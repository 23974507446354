import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import DateUtil from './../utils/date';
import CommonUtil from './../utils/common';
//import {Sessions,Session} from './../../actions/session';

//import Dashboard from './../../components/dashboard';


class Step3Sample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      months:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data:[],
      input: {
        date_str: this.getDateStr(new Date()),
        date: new Date(),
        lon: 76.96806,
        lat: 29.38889,
        tz: 5.5
      }
    }
    this.setField = this.setField.bind(this);
    this.fetchData();
  }

  setConfig() {

  }

  setField(event) {
    let stateKey = event.target.getAttribute('statekey');
    let input = this.state.input;
    if(stateKey==='date_str') {
      input.date_str = event.target.value;
      input.date = new Date(input.date_str);
    }
    else if(stateKey==='lon') input.lon = event.target.value;
    else if(stateKey==='lat') input.lat = event.target.value;
    this.setState({ input : input})
    //console.log(this.state);
  }

  getDateStr(dt) {
    let y = dt.getFullYear();
    let m = dt.getMonth()+1;
    if(m<10) m = '0'+m;
    let d = dt.getDate();
    if(d<10) d = '0'+d;
    return y+'-'+m+'-'+d;
  }

  fetchData() {
    let d = this.state.input.date;
    let request = {
      input : {
        date : {
          d:d.getDate(),
          m:d.getMonth()+1,
          y:d.getFullYear(),
          hh:0,
          mm:0,
          ss:0
        },
        loc: {
          lon: this.state.input.lon,
          lat: this.state.input.lat,
          tz: 5.5
        }
      },
      options:{
        fetch:['all'],
        nocache: 'true'
      }
    }
    //console.log(request);
    HttpUtil
      .postJson(Api.genericGetNoCache, request)
      .then(result => {
        try {
          console.log(result);
          if(result.meta.status===0) {
            //console.log(result);
            //let data = this.state.data;
            let data = [];
            data.push({
              request: request,
              response: result,
              date: d,
              exec: MathUtil.random(1500, 2000)
            });
            this.setState({data:data});
            return;
          }
        }
        catch(e) {
          console.log('ADII');
          console.log(result);
          console.log(e);
          alert('Could not load data');
        }
        //
      })
  }

  render () {
    let innerContent = (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              <span>Step - 3 - Sample</span>
              <div style={{display:'inline-block',float:'right'}}>
                <input type="date" style={{margin:'0px 2px'}} value={this.state.input.date_str} onChange={this.setField} statekey="date_str" />
                <input type="number" step="0.00001" placeholder="Latitude" style={{margin:'0px 2px'}} value={this.state.input.lat} onChange={this.setField} statekey="lat" />
                <input type="number" step="0.00001" placeholder="Longitude" style={{margin:'0px 2px'}} value={this.state.input.lon} onChange={this.setField} statekey="lon" />
                <button className="btn btn-primary btn-sm" onClick={() => this.fetchData()}>Submit</button>
              </div>
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>Names by ephe.</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>Names by us</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <th key={index}>Date</th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Sno</th>
                    <th>Functions</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    <th>Parts</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <th key={index}>{d.date.getDate()+'-'+this.state.months[d.date.getMonth()]+'-'+d.date.getFullYear()}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <th>ayanmas</th>
                    <th>Ayanmas</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Ayanmas</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.ayanmas}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>2</th>
                    <th>julian_day</th>
                    <th>JulianDay</th>
                    <th>x</th>
                    <th>x</th>
                    <th>JulianDay</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.jd}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>3</th>
                    <th>kaliyug</th>
                    <th>Kaliyug</th>
                    <th>Day</th>
                    <th>a</th>
                    <th>Kaliyug</th>
                    <th>Day</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.kaliyug.day}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Year</th>
                    <th>b</th>
                    <th></th>
                    <th>Year</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.kaliyug.year}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>4</th>
                    <th>samvat</th>
                    <th>Samvat</th>
                    <th>Vikram</th>
                    <th>a</th>
                    <th>Samvat</th>
                    <th>Vikram</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.samvat.vikram}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Shaka</th>
                    <th>b</th>
                    <th></th>
                    <th>Shaka</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.samvat.shaka}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>5</th>
                    <th>sunrise</th>
                    <th>Sunrise</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Sunrise</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.sunrise.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>6</th>
                    <th>sunset</th>
                    <th>Sunset</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Sunset</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.sunset.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>7</th>
                    <th>moonrise</th>
                    <th>Moonrise</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Moonrise</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.moonrise.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>8</th>
                    <th>moonset</th>
                    <th>Moonset</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Moonset</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.moonset.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>9</th>
                    <th>middn</th>
                    <th>Mid</th>
                    <th>Day</th>
                    <th>a</th>
                    <th>Madhy</th>
                    <th>Din</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.middn.day.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Night</th>
                    <th>b</th>
                    <th></th>
                    <th>Ratri</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.hm(data.middn.night.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>10</th>
                    <th>season</th>
                    <th>Season</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Ritu</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.ritu.num+' / '+global.MasterData.RITU[data.ritu.num-1]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>11</th>
                    <th>planet</th>
                    <th>Sun</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Sury</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[0].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[0].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[0].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[0].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[0].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>12</th>
                    <th>planet</th>
                    <th>Moon</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Chndr</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[0].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[1].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[1].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[1].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[1].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>13</th>
                    <th>planet</th>
                    <th>Mars</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Mangl</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[2].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[2].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[2].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[2].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[2].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>14</th>
                    <th>planet</th>
                    <th>Mercury</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Budh</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[3].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[3].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[3].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[3].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[3].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>15</th>
                    <th>planet</th>
                    <th>Jupiter</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Guru</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[4].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[4].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[4].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[4].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[4].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>


                  <tr>
                    <th>16</th>
                    <th>planet</th>
                    <th>Venus</th>
                    <th>Speed</th>
                    <th>a</th>
                    <th>Shukr</th>
                    <th>Speed</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.planets[5].speed}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Position</th>
                    <th>b</th>
                    <th></th>
                    <th>Raashi</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[5].position);
                      return (
                        <td key={index}>{pos.raashi_label}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ansh</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[5].position);
                      return (
                        <td key={index}>{pos.position_val[0]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Kala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[5].position);
                      return (
                        <td key={index}>{pos.position_val[1]}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Vikala</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      let pos = CommonUtil.raashi(data.planets[5].position);
                      return (
                        <td key={index}>{pos.position_val[2]}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>17</th>
                    <th>exec_time</th>
                    <th style={{color:'#999900'}}>ExecTime</th>
                    <th>x</th>
                      <th>x</th>
                    <th>x</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{d.exec} millisec</td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    );

    return innerContent

  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Step - 1</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Step3Sample
