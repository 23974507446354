class MasterCombinationConfig {
    static FIELDS = [
        {
            field:'karm_kaal',
            label: 'Karm Kaal',
            items: [
                {
                    label: 'प्रातः',
                    value: 'kkd1'
                },
                {
                    label: 'संगव',
                    value: 'kkd2'
                },
                {
                    label: 'मध्यांह',
                    value: 'kkd3'
                },
                {
                    label: 'उपरांह',
                    value: 'kkd4'
                },
                {
                    label: 'सायंह',
                    value: 'kkd5'
                },
                {
                    label: 'प्रदोष',
                    value: 'kkn1'
                },
                {
                    label: 'निशीथ',
                    value: 'kkn2'
                },
                {
                    label: 'महानिशीथ 4 VT',
                    value: 'kkn3'
                },
                {
                    label: 'निशीथ',
                    value: 'kkn4'
                },
                {
                    label: 'अरुणोदयी',
                    value: 'kkn5'
                }
            ]
        },
        {
            field:'vyapani',
            label: 'Vyapani',
            items: [
                {
                    label: 'सूर्योदयी',
                    value: 'srise'
                },
                {
                    label: 'सूर्यास्त',
                    value: 'sset'
                },
                {
                    label: 'चंद्रोदयी',
                    value: 'mrise'
                },
                {
                    label: 'चन्द्रास्त',
                    value: 'mset'
                },
                {
                    label: 'मध्यदिन',
                    value: 'midd'
                },
                {
                    label: 'मध्यरात्रि',
                    value: 'midn'
                }
            ]
        },
    ];
}
export default MasterCombinationConfig;