import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';
import MasterData from '../../utils/data';

class DashboardValidateEventData extends Component {

  state = {
    req: {
      templateId: this.props.params.templateId,
      categoryId: this.props.params.categoryId,
      year: this.props.params.year,
      col1: this.props.params.col1?this.props.params.col1:''
    },
    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    data: null
  };

  componentDidMount = () => {
    console.log(this.state.req);
    this.fetchCompileData();
  };

  fetchCompileData = () => {
    let formData = new FormData();
    formData.append('templateId', this.state.req.templateId);
    formData.append('categoryId', this.state.req.categoryId);
    formData.append('year', this.state.req.year);
    formData.append('col1', this.state.req.col1);
    HttpUtil
      .postFormData(Api.combinationCompileYearFetch, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }



  tableFormat = (data) => {
    let mainKey = '';
    let mainCounter = 1;
    let innerCounter = 1;
    let rows = [];
    let lastMonth = '-';
    for(let i in data) {
      let d = {...data[i]};
      let curKey = d.col1+'_'+d.col3;
      if(lastMonth!=d.col1) {
        mainCounter = 1;
        lastMonth = d.col1;
      }
      if(mainKey!=curKey) {
        d._props_ = {
          bg: 'rgba(0, 0, 0, 0.05)',
          sno: mainCounter+''
        }
        mainCounter += 1;
        innerCounter = 1;
        mainKey = curKey;
      }
      else {
        d._props_ = {
          bg: '#fff',
          sno: (mainCounter-1)+''+MasterData.CHARS[innerCounter-1]
        }
        innerCounter += 1;
      }
      rows.push(d);
    }
    return rows;
  }

  conditions = {
    kkd1: 'Karm Kaal - Day 1', kkd2: 'Karm Kaal - Day 2', kkd3: 'Karm Kaal - Day 3', kkd4: 'Karm Kaal - Day 4', kkd5: 'Karm Kaal - Day 5', 
    kkn1: 'Karm Kaal - Night 1', kkn2: 'Karm Kaal - Night 2', kkn3: 'Karm Kaal - Night 3', kkn4: 'Karm Kaal - Night 4', kkn5: 'Karm Kaal - Night 5', 
    srise: 'Sunrise', sset : 'Sunset',
    mrise: 'Moonrise', mset : 'Moonset',
  };
  getConditionStr = (s) => {
    if(s==null) return '';
    let tmp = s.split('_');
    if(!tmp[1]) return tmp[0];
    //if(this.conditions[tmp[1]]) return this.conditions[tmp[1]];
    return this.conditions[tmp[1]];
  };

  render = () => {
    let innerContent = (
      <div className="row">

      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-body my-cont">
            Loading data ...
          </div>
        </div>
      </div>

    </div>
    );

    if (this.state.data != null) {

      innerContent = (
        <div className="row">

          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  {this.state.data.category.label + ' ( ' + this.state.data.template.country + ' ) - ' + this.state.req.year}
                </h6>
              </div>
              <div className="card-body my-cont">
                <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>Year</th>
                      <th>Maas</th>
                      <th>Tithi</th>
                      <th>Event Name</th>
                      <th>Day</th>
                      <th>Date</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Yukta</th>
                      <th>Condition</th>
                      <th>Event Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.tableFormat(this.state.data.items).map((d, index) => {
                      let dt = new Date(d.date);
                      return (
                        <tr key={index} style={{background:d._props_.bg}}>
                          <td>{d._props_.sno}</td>
                          <td>{d.year}</td>
                          <td>{d.col2}</td>
                          <td>{d.col4}</td>
                          <td>{d.col5}</td>
                          <td>{this.state.days[dt.getDay()]}</td>
                          <td>{d.date}</td>
                          <td>{d.col8}</td>
                          <td>{d.col9}</td>
                          <td>{d.col6}</td>
                          <td>{this.getConditionStr(d.col7)}</td>
                          <td>{d.time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      );
    }

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardValidateEventData;
