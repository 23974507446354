import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';
import MasterData from '../../utils/data';
import MasterCombinationConfig from '../../configs/combinations';

class DashboardEventCombinationData extends Component {

  state = {
    req: {
      templateId: this.props.params.templateId,
      categoryId: this.props.params.categoryId,
      col1: this.props.params.col1?this.props.params.col1:''
    },
    data: [],
    fields: MasterCombinationConfig.FIELDS,
    combination: {},
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
  };

  componentDidMount = () => {
    console.log(this.state.req);
    this.fetchCompileData();
  };

  fetchCompileData = () => {
    let formData = new FormData();
    formData.append('templateId', this.state.req.templateId);
    formData.append('categoryId', this.state.req.categoryId);
    formData.append('col1', this.state.req.col1);
    HttpUtil
      .postFormData(Api.combinationCompileFetch, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  tableFormat = (data) => {
    let mainKey = '';
    let mainCounter = 1;
    let innerCounter = 1;
    let rows = [];
    let lastMonth = '-';
    for(let i in data) {
      let d = {...data[i]};
      let curKey = d.col1+'_'+d.col3;
      if(lastMonth!=d.col1) {
        mainCounter = 1;
        lastMonth = d.col1;
      }
      if(mainKey!=curKey) {
        d._props_ = {
          type: 'main',
          bg: 'rgba(0, 0, 0, 0.05)',
          sno: mainCounter+''
        }
        mainCounter += 1;
        innerCounter = 1;
        mainKey = curKey;
      }
      else {
        d._props_ = {
          type: 'sub',
          bg: '#fff',
          sno: ''
          //sno: (mainCounter-1)+''+MasterData.CHARS[innerCounter-1]
        }
        innerCounter += 1;
      }
      rows.push(d);
    }
    return rows;
  }

  combinationPopup = (d) => {
    let popup = this.state.popup;
    popup.show = true;
    popup.size = 8;
    popup.head = d.col2 + ' - Add Combination';
    popup.content = (
      <div className="my-cont">
        {this.state.fields.map((d, index) => {
          return (
            <div key={index} className="form-group">
              <label>{d.label}</label>
              <div style={{border:'1px dashed #ccc', padding:'10px'}}>
                {d.items.map((item, itemIndex) => {
                  return (
                    <span key={itemIndex} style={{margin:'5px'}}>
                      <input type="checkbox" onChange={(e) => {
                        let combination = this.state.combination;
                        let checked = {};
                        if(combination[d.field]) checked = combination[d.field];
                        if(e.target.checked) {
                          checked[item.value] = 'true';
                        }
                        else {
                          delete checked[item.value];
                        }
                        let deleteChecked = true;
                        for(let k in checked) {
                          deleteChecked = false;
                        }
                        if(deleteChecked) delete combination[d.field];
                        else combination[d.field] = checked;
                        this.setState({combination: combination});
                        //console.log(combination);
                      }} /> {item.label}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="form-group" style={{textAlign:'right'}}>
          <button className="btn btn-primary my-btn" onClick={() => {
            this.saveCombination(d);
          }}>Submit</button>
        </div>
      </div>
    );
    this.setState({popup: popup, combination: {}});
  };

  saveCombination = (d) => {
    let combObject = {
      comb: []
    };
    for(let k in this.state.combination) {
      let _c = this.state.combination;
      let items = [];
      for(let i in _c[k]) {
        items.push(i);
      }
      combObject.comb.push({
        field: k,
        items: items
      });
    }
    let formData = new FormData();
    formData.append('compileId', d.id);
    formData.append('data', JSON.stringify(combObject));
    HttpUtil
      .postFormData(Api.saveCombination, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            alert('Saved');
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  render = () => {    
    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Event - Combinations</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Month No</th>
                    <th>Month</th>
                    <th>Date No</th>
                    <th>Date</th>
                    <th>Event Name</th>
                    <th>Event Combination</th>
                    <th style={{width:'80px',textAlign:'right'}}>
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.tableFormat(this.state.data).map((d, index) => {
                    let btn = (<span />);
                    if(d._props_.type=='sub') {
                      btn = (
                        <button className="btn btn-primary my-btn" onClick={() => this.combinationPopup(d)}>
                          Combination
                        </button>
                      );
                    }
                    let comb = [];
                    try {
                      let combJson = JSON.parse(d.combData);
                      for(let i in combJson.comb) {
                        let c = combJson.comb[i];
                        comb.push(i + ': '+c.val);
                      }
                    }
                    catch(e) {
                      console.log(e);
                    }
                    return (
                      <tr key={index} style={{background:d._props_.bg}}>
                        <td>{d._props_.sno}</td>
                        <td>{d.col1}</td>
                        <td>{d.col2}</td>
                        <td>{d.col3}</td>
                        <td>{d.col4}</td>
                        <td>{d.col5}</td>
                        <td>{comb.join(' | ')}</td>
                        <td style={{textAlign:'right'}}>{btn}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardEventCombinationData;
