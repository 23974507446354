import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import MasterTemplates from './../../configs/templates';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardGeneratedDataOpen extends Component {

  state = {
    template: {
      default_base: 'Default / International English',
      country: '',
      category: '',
      name: '',
      base: '',
      type: '',
      typeSub1: '',
      typeSub2: '',
      file: ''
    },
    base: {
      data: MasterTemplates.DATA,
    },
    generated: {
      all: [],
      data: []
    },
  };

  componentDidMount = () => {
    this.fetchCompileYearList();
  };

  fetchCompileYearList = () => {
    HttpUtil
      .getJson(Api.combinationCompileYearFetchAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = {};
            for(let i in result.data) {
              let d = result.data[i];
              let key = d.template_id+'_'+d.category_id;
              if(!data[key]) {
                data[key] = {
                  info: d,
                  years: []
                };
              }
              data[key].years.push(d);
            }
            //console.log(data);
            let generated = this.state.generated;
            generated.all = data;
            this.setState({generated: generated});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  setFilters = () => {
    let data = [];
    let t = {...this.state.template};
    for(let k in this.state.generated.all) {
      let d = this.state.generated.all[k];
      if(t.base!='' && d.info.base!=t.base) continue;
      if(t.type!='' && d.info.type!=t.type) continue;
      if(t.typeSub1!='' && d.info.typeSub1!=t.typeSub1) continue;
      if(t.typeSub2!='' && d.info.typeSub2!=t.typeSub2) continue;
      data.push(d);
    }
    let generated = this.state.generated;
    generated.data = data;
    this.setState({generated: generated});
  };

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  render = () => {

    let selectCalendar = (
      <table className="table table-bordered myTable" width="100%" cellSpacing="0">
        <tbody>
          <tr>
            <th>Calendar Base</th>
            <th>Calendar Type</th>
            <th>Calendar Sub Type 1</th>
            <th>Calendar Sub Type 2</th>
          </tr>
          <tr>
            <td style={{width:'25%'}}>
              {this.selectCalendarBase()}
            </td>
            <td style={{width:'25%'}}>
              {this.selectCalendarType()}
            </td>
            <td style={{width:'25%'}}>
              {this.selectCalendarTypeSub1()}
            </td>
            <td style={{width:'25%'}}>
              {this.selectCalendarTypeSub2()}
            </td>
          </tr>
        </tbody>
      </table>
    );

    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Open Generated Data</h6>
            </div>
            <div className="card-body my-cont">
              {selectCalendar}
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'120px'}}>Country</th>
                    <th style={{width:'120px'}}>Calendar Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.generated.data.map((cat, catIndex) => {
                    return (
                      <tr style={{background:'#fff'}} key={catIndex}>
                        <td>{cat.info.country}</td>
                        <td>{cat.info.category}</td>
                        <td>
                          {cat.years.map((d, index) => {
                            return (
                              <a key={index} className="btn my-btn" style={{background:'#fff',border:'1px solid #ccc'}}  target="blank" href={'/compile-year/'+d.template_id+'/'+d.category_id+'/'+d.year}>
                                {d.year}
                              </a>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardGeneratedDataOpen;
