import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class CombinationTithi extends Component {

  state = {
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    input: {
      yukta: '',
      condition: '',
    },
    selected: null,    
    data: [],
    generated: [],
    generate: {
      status: 'start',
      done: []
    },
    worldDb : {      
      status: false,
      field: '',
      data: [],
      selected: null
    },
    step: 1,
    labels: {
      maas: ['Chaitr', 'Vaishakh', 'Jyesth', 'Aashadh', 'Shravan', 'Bhadon', 'Aashwin', 'Kartik', 'Margshirsh', 'Paush', 'Magh', 'Falgun'],
      tithi: [
        'Sh. 1', 'Sh. 2', 'Sh. 3', 'Sh. 4', 'Sh. 5', 'Sh. 6', 'Sh. 7', 'Sh. 8', 'Sh. 9', 'Sh. 10', 'Sh. 11', 'Sh. 12', 'Sh. 13', 'Sh. 14', 'Purnima',
        'Kr. 1', 'Kr. 2', 'Kr. 3', 'Kr. 4', 'Kr. 5', 'Kr. 6', 'Kr. 7', 'Kr. 8', 'Kr. 9', 'Kr. 10', 'Kr. 11', 'Kr. 12', 'Kr. 13', 'Kr. 14', 'Amaavas'
      ]
    }
  };

  componentDidMount = () => {
    this.fetchMasterTithiYukta();
  };

  fetchMasterTithiYukta = () => {
    HttpUtil
      .getJson(Api.combinationMasterYukta)
      .then(result => {
        try {
          if(result.meta.status===0) {
            //console.log(result.data);
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  tableFormat = (data) => {
    let mainCounter = 1;
    let rows = [];
    let lastMonth = '-';
    for(let i in data) {
      let d = {...data[i]};
      d.monthLabel = this.state.labels.maas[parseInt(d.month) - 1];
      d.dateOrgLabel = this.state.labels.tithi[parseInt(d.dateOrg) - 1];
      d.dateYuktaLabel = this.state.labels.tithi[parseInt(d.dateYukta) - 1];
      if(lastMonth!=d.month) {
        mainCounter = 1;
      }
      lastMonth = d.month;
      d.sno = mainCounter;
      rows.push(d);
      mainCounter += 1;
    }
    return rows;
  }

  combinationPopup = (d) => {
    let popup = this.state.popup;
    popup.show = true;
    popup.size = 8;
    popup.head = d.monthLabel + ' - ' + d.dateOrgLabel + ' - Update Combination';
    popup.content = (
      <div className="my-cont">
        <div className="form-group">
          <label>Tithi Yukta</label>
          <div style={{ border: '1px dashed #ccc', padding: '10px' }}>
            {this.state.labels.tithi.filter((t, index) => index<15).map((t, index) => {
              return (
                <span key={index} style={{margin:'5px'}}>
                  <input type="radio" name="radio" className="my-field" defaultChecked={((index+1)+'')==(d.dateYukta+'')} onChange={(e) => {
                    let input = this.state.input;
                    input.yukta = index+1;
                    this.setState({input: input});
                  }} /> &nbsp; 
                  {t}
                </span>
              );
            })}
            <br />
            {this.state.labels.tithi.filter((t, index) => index>=15).map((t, index) => {
              return (
                <span key={index} style={{margin:'5px'}}>
                  <input type="radio" name="radio" className="my-field" defaultChecked={((index+1+15)+'')==(d.dateYukta+'')} onChange={(e) => {
                    let input = this.state.input;
                    input.yukta = index+1+15;
                    this.setState({input: input});
                  }} /> &nbsp; 
                  {t}
                </span>
              );
            })}            
          </div>
        </div>
        <div className="form-group">
          <label>Condition</label>
          <input type="text" className="form-control my-field" defaultValue={d.condition} placeholder="e.g skip, always, between_kkd1, greater_kkd1" onChange={(e) => {
            let input = this.state.input;
            input.condition = e.target.value;
            this.setState({input: input});
          }} />
        </div>
        <div className="form-group" style={{textAlign:'right'}}>
          <button className="btn btn-primary my-btn" onClick={() => {
            this.saveCombination(d);
          }}>Submit</button>
        </div>
      </div>
    );
    this.setState({popup: popup, combination: {}});
  };

  saveCombination = (d) => {
    let formData = new FormData();
    formData.append('id', d.id);
    formData.append('yukta', this.state.input.yukta);
    formData.append('condition', this.state.input.condition);
    HttpUtil
      .postFormData(Api.combinationMasterYuktaSave, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            alert('Saved');
            this.fetchMasterTithiYukta();
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  render = () => {

    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Tithi Yukta - Combinations</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Maas</th>
                    <th>Maas No</th>
                    <th>Tithi</th>
                    <th>Tithi No</th>
                    <th>Tithi Yukta</th>
                    <th>Tithi Yukta No</th>
                    <th>Condition</th>
                    <th style={{width:'120px'}}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.tableFormat(this.state.data).map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>{d.sno}</td>
                        <td>{d.monthLabel}</td>
                        <td>{d.month}</td>
                        <td>{d.dateOrgLabel}</td>
                        <td>{d.dateOrg}</td>
                        <td>{d.dateYuktaLabel}</td>
                        <td>{d.dateYukta}</td>
                        <td>{d.condition}</td>
                        <td>
                          <button className="btn btn-primary my-btn" onClick={() => {
                            this.combinationPopup(d);
                          }}>
                            View Combination
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default CombinationTithi;
