import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import Formats from './../utils/data_formats';
//import {Sessions,Session} from './../../actions/session';

//import Dashboard from './../../components/dashboard';


class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxNum:30,
      data:[],
    }
    this.setData();
  }

  setData() {
    let data = [];
    for(let i=0; i<this.state.maxNum; i++) {
      data.push({
        i: i
      });
    }
    this.state.data = data;
  }


  render () {
    let I = 1;
    let innerContent = (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Step - 5</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th colSpan={2}>&nbsp;</th>
                    <th colSpan={2}>Names by ephe.</th>
                    <th colSpan={2}>Names by us</th>
                    <th colSpan={1}>&nbsp;</th>
                    <th colSpan={this.state.maxNum}>Numbers</th>
                  </tr>
                  <tr>
                    <th>Sno</th>
                    <th>Functions</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    <th>Format</th>
                    {this.state.data.map((d, index) => {

                      return (
                        <th key={index}>{d.i+1}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{I++}</td>
                    <td>raashi</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Raashi</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.RAASHI(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>x</td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.RAASHI(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>season</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Ritu</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.RITU(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>x</td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.RITU(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>tithi</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Tithi</td>
                    <td>Shukla</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.TITHI_SHUKL(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.TITHI_SHUKL(d.i+1).b}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Krishna</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.TITHI_KRISHN(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.TITHI_KRISHN(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>nakshatra</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Nakshatra</td>
                    <td>Type27</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.NAKSHATRA(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.NAKSHATRA(d.i+1).b}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Type28</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.NAKSHATRA_28(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.NAKSHATRA_28(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>vaar</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Vaar</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.VAAR(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.VAAR_SHORT(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>yog</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Yog</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.YOG(d.i+1).a}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>karan</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Karan</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.KARAN(d.i+1).a}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>choghadiya</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Choghadiya</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.CHOGHADIYA(d.i+1).a}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>hora</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Hora</td>
                    <td>x</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.HORA(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.HORA(d.i+1).b}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>{I++}</td>
                    <td>kaal</td>
                    <td>x</td>
                    <td>x</td>
                    <td>Kaal</td>
                    <td>Day</td>
                    <td>a</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.KAAL_DAY(d.i+1).a}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Night</td>
                    <td>b</td>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{Formats.KAAL_NIGHT(d.i+1).a}</td>
                      );
                    })}
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    );

    return innerContent

  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Step - 1</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Step1
