import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';
import MasterData from '../../utils/data';

class DashboardEventNew extends Component {

  state = {
    req: {
      templateId: this.props.params.templateId,
      categoryId: this.props.params.categoryId,
      col1: this.props.params.col1?this.props.params.col1:''
    },
    data: []
  };

  componentDidMount = () => {
    console.log(this.state.req);
    this.fetchCompileData();
  };

  fetchCompileData = () => {
    let formData = new FormData();
    formData.append('templateId', this.state.req.templateId);
    formData.append('categoryId', this.state.req.categoryId);
    formData.append('col1', this.state.req.col1);
    HttpUtil
      .postFormData(Api.combinationCompileFetch, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  tableFormat = (data) => {
    let mainKey = '';
    let mainCounter = 1;
    let innerCounter = 1;
    let rows = [];
    let lastMonth = '-';
    for(let i in data) {
      let d = {...data[i]};
      let curKey = d.col1+'_'+d.col3;
      if(lastMonth!=d.col1) {
        mainCounter = 1;
        lastMonth = d.col1;
      }
      if(mainKey!=curKey) {
        d._props_ = {
          bg: 'rgba(0, 0, 0, 0.05)',
          sno: mainCounter+''
        }
        mainCounter += 1;
        innerCounter = 1;
        mainKey = curKey;
      }
      else {
        d._props_ = {
          bg: '#fff',
          sno: (mainCounter-1)+''+MasterData.CHARS[innerCounter-1]
        }
        innerCounter += 1;
      }
      rows.push(d);
    }
    return rows;
  }

  render = () => {    
    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Compile Data</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Month No</th>
                    <th>Month</th>
                    <th>Date No</th>
                    <th>Date</th>
                    <th>Event Name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.tableFormat(this.state.data).map((d, index) => {
                    return (
                      <tr key={index} style={{background:d._props_.bg}}>
                        <td>{d._props_.sno}</td>
                        <td>{d.col1}</td>
                        <td>{d.col2}</td>
                        <td>{d.col3}</td>
                        <td>{d.col4}</td>
                        <td>{d.col5}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardEventNew;
