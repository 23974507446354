import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import MasterTemplates from './../../configs/templates';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardGenerateListMultiple extends Component {

  state = {
    template: {
      default_base: 'Default / International English',
      country: '',
      category: '',
      name: '',
      base: '',
      type: '',
      typeSub1: '',
      typeSub2: '',
      file: ''
    },
    base: {
      data: MasterTemplates.DATA,
    },
    data: [],
    generated: [],
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    input: {
      templateId: null,
      categoryId: null,
      year: '',
      year2: '',
      loc: null,
      userFullName: '',
      userEmail: '',
    },
    selected: null,    
    generate: {
      status: 'start',
      done: []
    },
    worldDb : {      
      status: false,
      field: '',
      data: [],
      selected: null
    },
    step: 1,
  };

  componentDidMount = () => {
    this.fetchCompileList();
    this.worldDbSearch('');
  };

  fetchCompileList = () => {
    HttpUtil
      .getJson(Api.combinationCompileFetchAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.setState({data: result.data});
            this.fetchCompileYearList();
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  fetchCompileYearList = () => {
    HttpUtil
      .getJson(Api.combinationCompileYearFetchAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = {};
            for(let i in result.data) {
              let d = result.data[i];
              let key = d.template_id+'_'+d.category_id;
              if(!data[key]) {
                data[key] = {
                  info: d,
                  years: []
                };
              }
              data[key].years.push(d);
            }
            this.setState({data: data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  saveCompileYear = () => {
    HttpUtil
      .postJson(Api.combinationCompileYearSave, {
        templateId: this.state.input.templateId,
        categoryId: this.state.input.categoryId,
        year: this.state.input.year,
        loc: JSON.stringify(this.state.input.loc),
        userFullName: this.state.input.userFullName,
        userEmail: this.state.input.userEmail,
      })
      .then(result => {
        try {
          if(result.meta.status===0) {
            let generate = this.state.generate;
            generate.status = 'done';
            this.setState({generate: generate});
            setTimeout(() => {
              let popup = this.state.popup;
              popup.content = this.getPopupContent();
              this.setState({popup:popup});
              this.fetchCompileList();
            }, 0);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  worldDbSearch = (val) => {
    let worldDb = this.state.worldDb;
    HttpUtil
      .postJson(Api.worldDb, {search: 'true', val: val, match: ''})
      .then(result => {
        try {
          if(result.found) {
            if(result.data) worldDb.data = result.data;
            else result.data = [];
            this.setState({worldDb:worldDb});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  }

  worldDbClose = () => {
    let worldDb = this.state.worldDb;
    worldDb.status = false;

    let popup = this.state.popup;
    popup.content = this.getPopupContent();
    this.setState({ worldDb: worldDb, popup: popup });
  }

  worldDbCont = () => {
    if(!this.state.worldDb.status) {
      return (<span />);
    }
    let data = [];
    for(let i in this.state.worldDb.data) {
      let loc = this.state.worldDb.data[i];
      if((loc[2]+'').toLocaleLowerCase().search(this.state.worldDb.field.toLocaleLowerCase())>=0) {
        data.push(loc);
      }
    }
    return (
      <div style={{ float: 'left', width: '100%', position: 'relative', height: '0px', zIndex: '999' }}>
        <div style={{ height: '200px', color: '#111', background: '#efefef', padding: '0px 5px', overflow: 'auto', border:'1px solid #ccc' }}>
          <div style={{float:'right',marginRight:'-5px'}}>
            <button style={{background:'#f66',width:'15px',height:'15px',lineHeight:'10px',textAlign:'center',border:'0px',outline:'none'}} onClick={() => this.worldDbClose()}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          {data.map((loc, index) => {
            return (
              <div key={index} style={{background:'#ccc', padding:'2px 10px', margin: '5px 0px', cursor:'pointer'}} onClick={() => {
                let worldDb = this.state.worldDb;
                worldDb.selected = loc;
                worldDb.field = loc[2];

                let input = this.state.input;
                input.loc = loc;
                this.setState({worldDb: worldDb, input: input});
                this.worldDbClose();
              }}>
                {loc[2]+' ('+loc[1]+')'}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getPopupContent = () => {
    if(this.state.step==1) {
      return (
        <div className="my-cont">
          <div className="row" style={{marginBottom:'20px'}}>
            <div className="col-lg-6">
              <label>From Year/Samvat</label>
              <input type="number" className="form-control my-field" value={this.state.input.year} placeholder="e.g 2016" onChange={(e) => {
                let input = this.state.input;
                input.year = e.target.value;
                this.setState({input: input});
                setTimeout(() => {
                  let popup = this.state.popup;
                  popup.content = this.getPopupContent();
                  this.setState({popup:popup});
                }, 0);
              }} />
            </div>
            <div className="col-lg-6">
              <label>Upto Year/Samvat</label>
              <input type="number" className="form-control my-field" value={this.state.input.year2} placeholder="e.g 2016" onChange={(e) => {
                let input = this.state.input;
                input.year2 = e.target.value;
                this.setState({input: input});
                setTimeout(() => {
                  let popup = this.state.popup;
                  popup.content = this.getPopupContent();
                  this.setState({popup:popup});
                }, 0);
              }} />
            </div>
          </div>
          <div className="form-group">
            <label>Search City</label>
            <input type="text" className="form-control my-field" value={this.state.worldDb.field} placeholder="e.g Panipat" onChange={(e) => {
              let worldDb = this.state.worldDb;
              worldDb.status = true;
              worldDb.field = e.target.value;
              this.setState({worldDb: worldDb});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);
            }} />
            {this.worldDbCont()}
          </div>
          <div className="form-group" style={{textAlign:'right'}}>
            <button className="btn btn-primary my-btn" onClick={() => {
              if(this.state.input.year.trim()=='' || this.state.input.year2.trim()=='' || this.state.input.loc==null) {
                alert('Please fill/select all values');
                return;
              }
              this.setState({step:2});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);
            }}>Next {'->'}</button>
          </div>
        </div>
      );
    }

    if(this.state.step==2) {
      return (
        <div className="my-cont">
          <div className="form-group">
            <label>Enter Your Name</label>
            <input type="text" className="form-control my-field" value={this.state.input.userFullName} placeholder="You Full Name" onChange={(e) => {
              let input = this.state.input;
              input.userFullName = e.target.value;
              this.setState({input: input});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);
            }} />
          </div>
          <div className="form-group">
            <label>Enter Your Email</label>
            <input type="text" className="form-control my-field" value={this.state.input.userEmail} placeholder="Your Email Address" onChange={(e) => {
              let input = this.state.input;
              input.userEmail = e.target.value;
              this.setState({input: input});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);
            }} />
          </div>
          <div className="form-group" style={{textAlign:'right'}}>
            <button className="btn btn-primary my-btn" onClick={() => {
              this.setState({step:1});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);              
            }}>{'<-'} Back</button>
            <button className="btn btn-primary my-btn" onClick={() => {
              if(this.state.input.userFullName.trim()=='' || this.state.input.userEmail.trim()=='') {
                alert('Please fill/select all values');
                return;
              }
              this.setState({step:3});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);              
            }}>Next {'->'}</button>
          </div>
        </div>
      );
    }

    if(this.state.step==3) {
      return (
        <div className="my-cont">
          <div className="form-group">
            <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
              <tbody>
                <tr>
                  <th>Your Name</th>
                  <td>{this.state.input.userFullName}</td>
                </tr>
                <tr>
                  <th>Your Email</th>
                  <td>{this.state.input.userEmail}</td>
                </tr>
                <tr>
                  <th>From Year/Samvat</th>
                  <td>{this.state.input.year}</td>
                </tr>
                <tr>
                  <th>Upto Year/Samvat</th>
                  <td>{this.state.input.year2}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{this.state.input.loc[2]}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{this.state.input.loc[1]}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{this.state.input.loc[0][0]}</td>
                </tr>
                <tr>
                  <th>Timezone</th>
                  <td>{this.state.input.loc[0][1]}</td>
                </tr>
                <tr>
                  <th>Latitude</th>
                  <td>{this.state.input.loc[3]}</td>
                </tr>
                <tr>
                  <th>Longitude</th>
                  <td>{this.state.input.loc[4]}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-group" style={{textAlign:'right'}}>
            <button className="btn btn-primary my-btn" onClick={() => {
              this.setState({step:2});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
              }, 0);
              }}>{'<-'} Back</button>
            <button className="btn btn-success my-btn" onClick={() => {
              let generate = this.state.generate;
              generate.status = 'progress';
              this.setState({generate: generate, step:4});
              setTimeout(() => {
                let popup = this.state.popup;
                popup.content = this.getPopupContent();
                this.setState({popup:popup});
                this.saveCompileYear();
              }, 0);
            }}>Submit</button>
          </div>
        </div>
      );
    }

    if(this.state.generate.status=='progress' || this.state.generate.status=='done') {
      let url = '/compile-year/'+this.state.input.templateId+'/'+this.state.input.categoryId+'/'+this.state.input.year;
      let progress = this.state.generate.status=='progress'?(
        <div className="text-primary">
          <i className="fa fa-spin fa-circle-notch"></i> Compile in progress ...
        </div>
      ):(<div className="text-success"><i className="fa fa-home"></i> Compile succesful ...</div>);
      let preview = (<div />);
      if(this.state.generate.status=='done') {
        preview = (
          <div style={{marginTop:'20px'}}>
            <a className="btn btn-success my-btn" target="blank" href={url}>Full Year Preview</a>
          </div>
        );
      }
      return (
        <div>
          {progress}
          {preview}
        </div>
      );
    }

    return (<span>--</span>);
  };

  popupReset = () => {
    let generate = this.state.generate;
    generate.status = 'start';

    let worldDb = this.state.worldDb;
    worldDb.status = false;
    worldDb.field = '';
    worldDb.selected = null;

    let input = this.state.input;
    input.year = '';
    input.loc = null;
    input.userFullName = '';
    input.userEmail = '';
    this.setState({generate: generate, step: 1, worldDb: worldDb, input:input});
  }



  setFilters = () => {
    let generated = [];
    for(let k in this.state.data) {
      let g = this.state.data[k];
      let d = {...g.info};
      if((d.base+'').search(this.state.template.base)<0) continue;
      if((d.type+'').search(this.state.template.type)<0 && this.state.template.type!='') continue;
      if((d.typeSub1+'').search(this.state.template.typeSub1)<0 && this.state.template.typeSub1!='') continue;
      if((d.typeSub2+'').search(this.state.template.typeSub2)<0 && this.state.template.typeSub2!='') continue;
      generated.push(g);
    }
    this.setState({generated: generated});
  };

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  render = () => {
    let openCont = (
      <div className="form-group alert alert-warning">
        No data found. Please choose specific calendars.
      </div>
    );
    if(this.state.generated.length>0) {
      openCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan={7}>Calendar</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
            <tr>
              <th>Sno</th>
              <th>Country</th>
              <th>Category</th>
              <th>Name</th>
              <th>Base</th>
              <th>Type</th>
              <th>Sub Type 1</th>
              <th>Sub Type 2</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.generated.map((cat, index) => {
              let d = cat.info;
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{d.country}</td>
                  <td>{d.category}</td>
                  <td>{d.name}</td>
                  <td>{d.base}</td>
                  <td>{d.type}</td>
                  <td>{d.typeSub1}</td>
                  <td>{d.typeSub2}</td>
                  <td>
                    <button className="btn btn-success btn-sm my-btn" onClick={() => {
                      this.popupReset();
                      setTimeout(() => {
                        let popup = this.state.popup;
                        popup.show = true;
                        popup.head = (
                          <div>
                            <div>Generate Year with Event</div>
                            <div style={{ fontSize: '12px', color: '#999', marginTop: '5px' }}>{d.country + ' (' + d.state + ') - ' + d.category}</div>
                          </div>
                        );
                        popup.content = this.getPopupContent();

                        let input = this.state.input;
                        input.templateId = d.template_id;
                        input.categoryId = d.category_id
                        this.setState({ popup: popup, selected: d, input: input });
                      }, 0);
                    }}>
                      Generate Multiple Years
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>  
      );
    }

    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">To Be Generate - Multiple List</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered myTable" width="100%" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Calendar Base</th>
                    <th>Calendar Type</th>
                    <th>Calendar Sub Type 1</th>
                    <th>Calendar Sub Type 2</th>
                  </tr>
                  <tr>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarBase()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarType()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub1()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub2()}
                    </td>
                  </tr>
                </tbody>
              </table>
              {openCont}
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Generated Years - List</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'100px'}}>Country</th>
                    <th style={{width:'180px'}}>Calendar Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.generated.map((cat, catIndex) => {
                    return (
                      <tr style={{background:'#fff'}} key={catIndex}>
                        <td>{cat.info.country}</td>
                        <td>{cat.info.category}</td>
                        <td>
                          {cat.years.map((d, index) => {
                            return (
                              <a key={index} className="btn my-btn" style={{background:'#fff',border:'1px solid #ccc'}}  target="blank" href={'/validate-event/'+d.template_id+'/'+d.category_id+'/'+d.year}>
                                {d.year}
                              </a>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardGenerateListMultiple;
