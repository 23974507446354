import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';


class EphemerisHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    let innerContent = (
      <div className="row">
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <a href="/step-1" className="card-body">
              Default Coding (1st)
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <a href="/step-2" className="card-body">
              Default Number (2nd) / For Cache
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <a href="/step-3" className="card-body">
              Default Name (3rd)
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <a href="/step-4" className="card-body">
              Default Tags (4th)
            </a>
          </div>
        </div>
      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  }
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Ephmeris Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default EphemerisHome;
