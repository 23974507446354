import React, {Component} from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import {Sessions,Session} from './../../actions/session';

const Dashboard = (props) => {
  let header = (typeof(props.header)!=='undefined')?props.header:<Header />;
  let footer = (typeof(props.footer)!=='undefined')?props.footer:<Footer />;
  return (
    <div>
      {header}
      <Content {...props} />
      {footer}
    </div>
  )
};

const ContentMenu = (props) => {
  if(props.disableMenu) {
    return (<span />);
  }
  let logoStyle = {
    width:"38px",
    backgroundColor: "#fff",
    padding: "2px",
    borderRadius:"5px",
    display:"inline-block"
  };
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
        <div className="sidebar-brand-icon rotate-n-15_">
          {/*<i className="fas fa-laugh-wink"></i>*/}
          <span style={logoStyle}><img src="/logo512.png" alt="Logo" style={{width:"100%"}} /></span>
        </div>
        <div className="sidebar-brand-text mx-3">Nirwanji</div>
      </a>
      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        {/*nav-link collapsed, collapse show, active collapse-item*/}
        <a className="nav-link" href="/collective-data/open">
          <i className="fas fa-fw fa-film"></i>
          <span>Open Collective Data</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="/generated-data/open">
          <i className="fas fa-fw fa-database"></i>
          <span>Open Generated Data</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="/ephemeris">
          <i className="fas fa-fw fa-database"></i>
          <span>Ephemeris</span>
        </a>
      </li>

    </ul>
  );
}

const Content = (props) => {
  return (
    <div>
      <div id="wrapper">
        <ContentMenu props={props} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                <li className="nav-item dropdown no-arrow">
                  <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{Sessions.get(Session.username)}</span>
                    <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" alt="Avatar" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <a className="dropdown-item" href="/">
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/logout">
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>

            <div className="container-fluid">
              <h1 className="h3 mb-4 text-gray-800">{props.heading}</h1>
              {props.content}
            </div>
          </div>

          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Nirwanji 2020</span>
              </div>
            </div>
          </footer>
        </div>
      </div>

      {
        /*
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        */
      }

    </div>
  )
}

class ButtonPublish extends Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
    this.process = this.process.bind(this);
  }
  initState() {
    return {
      html: <span><i className="fa fa-check"></i> Publish</span>,
    };
  }
  process() {
    this.setState({html:<span><i className="fa fa-circle-notch fa-spin"></i> Publish</span>});
    HttpUtil
      .getJson(Api.publish)
      .then(result => {
        console.log(result);
        if(result.meta.status===0) {
          this.setState(this.initState());
        }
      })
  }
  render() {
    return (
      <button type="button" onClick={this.process} className="btn btn-success" target="blank">
        {this.state.html}
      </button>
    );
  }
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Dashboard - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Dashboard
