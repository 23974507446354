class Formats {

  static PLANET(num) {
    if(!global.MasterData.PLANET[num-1]) return Formats.defaultVals();
    return {
      a : (num)+' / '+global.MasterData.PLANET[num-1],
      b : global.MasterData.PLANET_EN[num-1]
    }
  }

  static RAASHI(num) {
    if(!global.MasterData.RAASHI[num-1]) return Formats.defaultVals();
    return {
      a : (num)+' / '+global.MasterData.RAASHI[num-1],
      b : global.MasterData.RAASHI[num-1]
    }
  }

  static RITU(num) {
    if(!global.MasterData.RITU[num-1]) return Formats.defaultVals();
    return {
      a : (num)+' / '+global.MasterData.RITU[num-1],
      b : global.MasterData.RITU[num-1],
      //c : (<span>{global.MasterData.RITU[num-1]}<sup>{num+'th'}</sup></span>)
    }
  }

  static MAAS(num) {
    if(!global.MasterData.MAAS[num-1]) return Formats.defaultVals();
    return {
      a : (num)+' / '+global.MasterData.MAAS[num-1],
      b : global.MasterData.MAAS[num-1],
      //c : (<span>{global.MasterData.MAAS[num-1]}<sup>{num+'th'}</sup></span>)
    }
  }

  static TITHI_SHUKL(num) {
    if(!global.MasterData.TITHI_SHUKL[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.TITHI_SHUKL[num-1],
      b : global.MasterData.TITHI_SHUKL_SHORT[num-1],
    }
  }

  static TITHI_KRISHN(num) {
    if(!global.MasterData.TITHI_KRISHN[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.TITHI_KRISHN[num-1],
      b : global.MasterData.TITHI_KRISHN_SHORT[num-1],
    }
  }

  static NAKSHATRA(num) {
    if(!global.MasterData.NAKSHATRA[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.NAKSHATRA[num-1],
      b : global.MasterData.NAKSHATRA_SHORT[num-1],
    }
  }

  static NAKSHATRA_28(num) {
    if(!global.MasterData.NAKSHATRA_28[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.NAKSHATRA_28[num-1],
      b : global.MasterData.NAKSHATRA_28_SHORT[num-1],
    }
  }

  static VAAR(num) {
    if(!global.MasterData.VAAR[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.VAAR[num-1],
      b : global.MasterData.VAAR[num-1],
    }
  }
  static VAAR_SHORT(num) {
    if(!global.MasterData.VAAR_SHORT[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.VAAR_SHORT[num-1],
      b : global.MasterData.VAAR_SHORT[num-1],
    }
  }

  static YOG(num) {
    if(!global.MasterData.YOG[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.YOG[num-1],
      b : global.MasterData.YOG[num-1],
    }
  }

  static KARAN(num) {
    if(!global.MasterData.KARAN[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.KARAN[num-1],
      b : global.MasterData.KARAN[num-1],
    }
  }

  static CHOGHADIYA(num) {
    if(!global.MasterData.CHOGHADIYA[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.CHOGHADIYA[num-1],
      b : global.MasterData.CHOGHADIYA[num-1],
    }
  }

  static HORA(num) {
    if(!global.MasterData.HORA[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.HORA[num-1],
      b : global.MasterData.HORA_SHORT[num-1],
    }
  }

  static KAAL_DAY(num) {
    if(!global.MasterData.KAAL_DAY[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.KAAL_DAY[num-1],
      b : global.MasterData.KAAL_DAY[num-1],
    }
  }
  static KAAL_NIGHT(num) {
    if(!global.MasterData.KAAL_NIGHT[num-1]) return Formats.defaultVals();
    return {
      a : global.MasterData.KAAL_NIGHT[num-1],
      b : global.MasterData.KAAL_NIGHT[num-1],
    }
  }

  static defaultVals() {
    return {
      a : '',
      b : ''
    };
  }

}


export default Formats
