import {SessionConfig} from './../configs/session';
export class Sessions {

  static set(authDto) {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    storage.setItem(SessionConfig.prefix+Session.id, authDto.id);
    storage.setItem(SessionConfig.prefix+Session.loginHash, authDto.loginHash);
    storage.setItem(SessionConfig.prefix+Session.loginKey, authDto.loginKey);
  }

  static get(sessionName) {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    return storage.getItem(SessionConfig.prefix+sessionName);
  }

  static clear() {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    storage.clear();
  }

}

export class Session {
  static id = 'id';
  static loginHash = 'loginHash';
  static loginKey = 'loginKey';
}
