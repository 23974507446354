class MathUtil {

  static random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}


export default MathUtil
