import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import Formats from './../utils/data_formats';
import CombinationData from './../components/combination_data';

import Popup from './../components/common/popup_new';

import {useParams} from 'react-router-dom';


class Compare extends Component {

  constructor(props) {
    super(props);
    this.state = {
      master:null,
      hidden:{},
      templateCode:this.props.params.calendar,
      categoryId:this.props.params.categoryId,
      popupProps: {
        status: false,
        opts: {
          size: 12,
          head: '',
          content: '',
        },
        onHide: () => {
          let popupProps = this.state.popupProps;
          popupProps.status = false;
          this.setState({popupProps:popupProps});
        }
      },
      //popup: <Popup size={12} />,
      //popupContent: null,
    };
    this.fetchTemplate();
  }

  fetchTemplate = (filter) => {
    let request = {};
    let url = Api.compareGet+'?templateCode='+this.state.templateCode+'&categoryId='+this.state.categoryId;
    if(filter) url = Api.compareGet+'?templateCode='+this.state.templateCode+'&categoryId='+this.state.categoryId+'&filter='+filter;
    if(this.state.master) {
      let master = this.state.master;
      master.rows = [];
      this.setState({master:master});
    }
    HttpUtil
      .getJson(url)
      .then(result => {
        console.log(result);
        try {
          if(result.meta.status===0) {
            let master = result.data;
            if(filter) {
              master.template.filter.selected = filter;
            }
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        //alert('Could not load data');
      });
  };

  fetchDataRow = (rowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        console.log(result);
        try {
          if(result.meta.status===0) {
            master.rows[rowIndex] = result.data;
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        //alert('Could not load data');
      });
  };

  getColumn = (obj, colNum) => {
    return obj['col'+colNum];
  };

  addMasterRow = (rowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetBlankDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let dataRow = result.data;
            for(let colIndex in this.state.master.template.columns) {
              let col = this.state.master.template.columns[colIndex];
              if(col.copy=='true') {
                dataRow['col'+col.col] = this.state.master.rows[rowIndex]['col'+col.col];
              }
            }
            master.rows[rowIndex].items.unshift(dataRow);
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  addDataRow = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetBlankDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let dataRow = result.data;
            for(let colIndex in this.state.master.template.columns) {
              let col = this.state.master.template.columns[colIndex];
              if(col.copy=='true') {
                dataRow['col'+col.col] = this.state.master.rows[rowIndex]['col'+col.col];
              }
            }

            //let dataRow = JSON.parse(JSON.stringify(master.rows[rowIndex].items[dataRowIndex]));
            dataRow.id = 0;
            master.rows[rowIndex].items.splice(dataRowIndex+1, 0, dataRow);
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  dataGo = (rowIndex, dataRowIndex) => {
    let request = {
      newRow: this.state.master.rows[rowIndex].items[dataRowIndex],
      series: []
    };
    if(!request.newRow.editStarted) {
      let sno = 0;
      for(let i in this.state.master.rows[rowIndex].items) {
        let d = this.state.master.rows[rowIndex].items[i];
        if(i!=dataRowIndex && d.id==0) continue;
        sno += 1;
        if(i==dataRowIndex) {
          request.newRow.sno = sno;
          continue;
        }
        request.series.push({
          id: d.id,
          sno: sno
        });
      }
    }
    else {
      delete request.newRow.editStarted;
    }
    HttpUtil
      .postJson(Api.combinationPostAddDataRow, request)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let master = this.state.master;
            master.rows[rowIndex].items[dataRowIndex] = result.data;
            this.setState({master:master});
            this.updatePopupContent(rowIndex, dataRowIndex);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  }

  dataEdit = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    master.rows[rowIndex].items[dataRowIndex].editStarted = true;
    this.setState({master:master});
    this.updatePopupContent(rowIndex, dataRowIndex);
  }

  dataDelete = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    let request = {
      deleteRow: master.rows[rowIndex].items[dataRowIndex],
      series: []
    };
    let sno = 0;
    for(let i in master.rows[rowIndex].items) {
      let d = master.rows[rowIndex].items[i];
      if(i==dataRowIndex || d.id==0) continue;
      sno += 1;
      request.series.push({
        id: d.id,
        sno: sno
      });
    }
    HttpUtil
      .postJson(Api.combinationPostDeleteDataRow, request)
      .then(result => {
        try {
          if(result.meta.status===0) {
            delete master.rows[rowIndex].items[dataRowIndex];
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  }

  updatePopupContent = (rowIndex, dataRowIndex) => {
    let popupProps = this.state.popupProps;
    popupProps.opts.content = this.getPopupContent(rowIndex, dataRowIndex);
    this.setState({popupProps:popupProps});
  }

  getPopupContent = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    let dataRow = master.rows[rowIndex].items[dataRowIndex];

    let btnGo = (<button className="btn btn-success btn-sm my-btn" disabled>Go</button>);
    let btnEdit = (<button className="btn btn-warning btn-sm my-btn" onClick={() => this.dataEdit(rowIndex, dataRowIndex)}>Edit</button>);
    let btnDel = (
      <button className="btn btn-danger btn-sm my-btn" onClick={() => this.dataDelete(rowIndex, dataRowIndex)}>
        <i className="fa fa-times" />
      </button>
    );

    if(dataRow.id==0) {
      btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
      btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
      btnDel = (
        <button className="btn btn-danger btn-sm my-btn" onClick={() => {
            let master = this.state.master;
            delete master.rows[rowIndex].items[dataRowIndex];
            this.setState({master:master});
          }}><i className="fa fa-times" /></button>
      );
    }

    if(dataRow.editStarted) {
      btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
      btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-striped_ myTable" id="dataTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              {
                this.state.master.template.columns
                  .filter(col => col.negative=='true')
                  .map((c, index) => {
                    return (<th key={index}>{c.label}</th>);
                })
              }
              <th style={{width:'150px'}}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.state.master.template.columns
               .filter(col => col.negative=='true')
               .map((col, colIndex) => {
                let val = this.getColumn(dataRow, col.col);
                let cType = col.type.split('.');
                if(col.edit=='false' || (!dataRow.editStarted && col.edit=='true' && dataRow.id!=0)) {
                  if (cType[0]=='select') {
                    let selectData = CombinationData.get(cType[1]);
                    for(let i in selectData) {
                      if(selectData[i][0]==val && val!='') {
                        return (<td key={colIndex} style={{color:'#f00'}}>{selectData[i][1]}</td>);
                      }
                    }
                    return (<td key={colIndex}>{val}</td>);
                  }
                  return (<td key={colIndex}>{val}</td>);
                }
                if (cType[0]=='num') {
                  return (
                    <td key={colIndex}>
                      <input
                        type="number"
                        className="form-control my-field"
                        value={(val==null)?'':val}
                        style={{color:'#f00'}}
                        onChange={(e) => {
                          let master = this.state.master;
                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col ] = e.target.value;
                          this.setState({master:master});
                        }}/>
                    </td>
                  );
                }
                else if (cType[0]=='select') {
                  let selectData = CombinationData.get(cType[1]);
                  return (
                    <td key={colIndex}>
                      <select
                        className="form-control my-field"
                        value={(val==null)?'':val}
                        style={{color:'#f00'}}
                        onChange={(e) => {
                          let master = this.state.master;
                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col] = e.target.value
                          this.setState({master:master});
                          this.updatePopupContent(rowIndex, dataRowIndex);
                        }}>
                        {
                          selectData.map((d, index) => {
                            return (<option key={index} value={d[0]}>{d[1]}</option>);
                          })
                        }
                      </select>
                    </td>
                  );
                }
                return (
                  <td key={colIndex}>
                    <input
                      type="text"
                      className="form-control my-field"
                      value={(val==null)?'':val}
                      style={{color:'#f00'}}
                      onChange={(e) => {
                        let master = this.state.master;
                        master.rows[rowIndex].items[dataRowIndex]['col'+col.col ] = e.target.value;
                        this.setState({master:master});
                      }}/>
                  </td>
                );
              })}
              <td>
                {btnGo}
                {btnEdit}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render () {
    if(this.state.master==null) return (
      <div className="row">
        <div className="col-lg-12">
          Loading Master Data ...
        </div>
      </div>
    );

    let innerContent = (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <table style={{width:'100%'}}>
              <tbody>
                <tr>
                  <td style={{width:'33%',textAlign:'left'}}>
                    <span className="m-0 font-weight-bold text-primary" style={{float:'left'}}>
                      {this.state.master.template.code} - compare - {this.state.master.category.label}
                    </span>
                  </td>
                  <td style={{width:'33%',textAlign:'center'}}>
                    <select
                      className="form-control my-field"
                      style={{display:'inline-block',width:'100px'}}
                      value={this.state.master.template.filter.selected}
                      onChange={(e) => {
                        this.fetchTemplate(e.target.value);
                      }}>
                      {
                        this.state.master.template.filter.labels.map((label, index) => {
                          let filter = this.state.master.template.filter;
                          return (<option key={index} value={filter.values[index]}>{label}</option>);
                        })
                      }
                    </select>
                  </td>
                  <td style={{width:'33%',textAlign:'right',fontSize:'9px'}}>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template.columns
                      .filter(col => col.hide && col.negative=='false' && col.group)
                      .map((col, index) => {
                        return (
                          <th key={index} colSpan={col.group.colspan}>{col.group.label}</th>
                        );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template.columns
                      .filter(col => col.hide && col.negative=='false')
                      .map((col, index) => {
                        return (
                          <th key={index}>{col.label}</th>
                        );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template.columns
                      .filter(col => col.hide && col.negative=='false')
                      .map((col, index) => {
                      return (
                        <th style={{textAlign:'left'}} key={index}>
                          <input type="checkbox" style={{width:'10px'}} defaultChecked={!this.state.hidden[col.col]} onChange={(e) => {
                              let hidden = this.state.hidden;
                              if(e.target.checked) delete hidden[col.col];
                              else hidden[col.col] = true;
                              this.setState({hidden:hidden});
                            }} />
                        </th>
                      );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'30px'}}>Sno</th>
                    {
                      this.state.master.template.columns
                        .filter(col => !this.state.hidden[col.col] && col.negative=='false')
                        .map((c, index) => {
                          return (<th key={index}>{c.label}</th>);
                      })
                    }
                    <th style={{width:'150px'}}></th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.master.rows
                    //.filter(row => this.state.master.template.filter.selected==this.getColumn(row, this.state.master.template.filter.mapped_column))
                    .map((row, rowIndex) => {
                    return (
                        <tr key={rowIndex}>
                          <td>{rowIndex+1}</td>
                          {this.state.master.template.columns
                            .filter(col => !this.state.hidden[col.col] && col.negative=='false')
                            .map((col, colIndex) => {
                            return (<td key={colIndex}>{this.getColumn(row, col.col)}</td>)
                          })}
                          <td>
                          </td>
                        </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup props={this.state.popupProps} />
    </div>
    );
    return innerContent
  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Festival - Add</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Compare
