import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import MasterTemplates from './../../configs/templates';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardEventNew extends Component {

  state = {
    template: {
      default_base: 'Default / International English',
      country: '',
      category: '',
      name: '',
      base: '',
      type: '',
      typeSub1: '',
      typeSub2: '',
      file: ''
    },
    base: {
      data: MasterTemplates.DATA,
    },
    generate: {
      fields: {
        itemName: '',
        itemQty: 0,
      },
      items: [],
      itemsBiggestQty: 0,
      itemsMaxQty: 15,
      addItem: false,
    },
    data: [],
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    selected: null,
    categories: [],
    categoryValue: null,
    categoryMake: false,
    excel: {
      file: null,
      cols: [],
      rows: [],
      selected: {},
    },
  };

  componentDidMount = () => {
    this.getTemplateMaster();
  };

  saveTemplateMaster = (inp) => {
    let req = {...inp};
    req.base_default = req.base_default.trim();
    req.country = req.country.trim();
    req.category = req.category.trim();
    if(req.category=='') req.category = 'General';
    req.base = req.base.trim();
    req.type = req.type.trim();
    req.typeSub1 = req.typeSub1.trim();
    req.typeSub2 = req.typeSub2.trim();
    req.name = req.name.trim();
    if(req.base_default=='' || req.country=='' || req.name=='') {
      alert('Please fill values in all fields');
      return;
    }
    if(req.editing) delete req.editing;
    req.template_type = 'event';
    if(!req.id) req.init_content = JSON.stringify(this.excelGetContentForSave());
    
    HttpUtil
      .postJson(Api.combinationTemplateMasterSave, req)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = '';
            this.setState({popup: popup});            
            this.getTemplateMaster();
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  deleteTemplateMaster = (inp) => {
    if(!window.confirm('Are you sure to delete ?')) {
      return;
    }
    let req = {...inp};
    HttpUtil
      .postJson(Api.combinationTemplateMasterDelete+req.id, {})
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.getTemplateMaster();
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  getTemplateMaster = () => {
    HttpUtil
      .getJson(Api.combinationTemplateMasterGet+'?templateType=event')
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.setState({data:result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  addTemplateItem = () => {
    let generate = this.state.generate;
    let item = generate.fields;
    let items = generate.items;
    let sub = [];

    if(item.itemQty>generate.itemsBiggestQty) generate.itemsBiggestQty = item.itemQty;

    for(let i=0; i<item.itemQty; i++) {
      sub.push({
        name: '',
        num: i+1,
      });
    }
    items.push({
      name: item.itemName,
      qty: item.itemQty,
      sub: sub
    });

    generate.items = items;
    generate.fields.itemName = '';
    generate.fields.itemQty = 0;
    generate.addItem = false;

    this.setState({generate: generate});
    this.popupRefresh(this.popupTemplateItems());
  };

  popupTemplateItems = () => {
    let addItem = <tbody />;
    if(this.state.generate.addItem) {
      addItem = (
        <tbody>
          <tr>
            <td></td>
            <td>
              <input type="text" className="form-control my-field" placeholder="e.g Maas / Months etc" onChange={(e) => {
                let generate = this.state.generate;
                generate.fields.itemName = e.target.value;
                this.setState({ generate: generate });
              }} />
            </td>
            <td colSpan={this.state.generate.itemsMaxQty}>
              <input type="number" className="form-control my-field" placeholder="e.g 12, 13 etc" style={{maxWidth:'100px'}} onChange={(e) => {
                let generate = this.state.generate;
                generate.fields.itemQty = parseInt(e.target.value);
                this.setState({ generate: generate });
              }} />
            </td>
            <td>
              <button className="btn btn-success my-btn" style={{ float: 'right' }} onClick={() => {
                this.addTemplateItem();
              }}>Go</button>
            </td>
          </tr>
        </tbody>
      );
    }

    let submitBtnCont = <tbody />;
    if(this.state.generate.items.length>0) {
      submitBtnCont = (
        <tbody>
          <tr>
            <td colSpan={500}>
              <button className="btn btn-success my-btn" style={{ float: 'right' }} onClick={() => {
                let d = this.state.selected;
                d.is_generated = 1;
                d.info = JSON.stringify(this.getTemplateJson());
                this.saveTemplateMaster(d);
                this.saveCategory({
                  templateId: d.id,
                  label: d.name
                });
              }}>Submit</button>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
        <thead>
          <tr>
            <th style={{ width: '20px' }}>Sno</th>
            <th style={{ width: '70px' }}>Items Name</th>
            <th colSpan={this.state.generate.itemsMaxQty}>Items Qty</th>
            <th style={{width:'30px'}}>
              <button className="btn btn-primary my-btn" style={{ float: 'right' }}
                onClick={() => {
                  let generate = this.state.generate;
                  generate.addItem = !generate.addItem;
                  this.setState({generate: generate});
                  this.popupRefresh(this.popupTemplateItems());
                }}>
                <i className="fa fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        {this.state.generate.items.map((item, itemIndex) => {
          let rowCounter = Math.floor(item.sub.length / this.state.generate.itemsMaxQty);
          if (rowCounter > 0 && item.sub.length % this.state.generate.itemsMaxQty == 0) rowCounter -= 1;
          let subArrs = [];
          let temp = [];
          for (let s in item.sub) {
            temp.push(item.sub[s]);
            if ((parseInt(s) + 1) % this.state.generate.itemsMaxQty == 0) {
              subArrs.push(temp);
              temp = [];
            }
          }
          if (temp.length != 0) subArrs.push(temp);

          let blanksMain = [];
          if (subArrs[0].length < this.state.generate.itemsMaxQty) {
            for (let i = 0; i < (this.state.generate.itemsMaxQty - subArrs[0].length); i++) {
              blanksMain.push((<td key={i}>&nbsp;</td>));
            }
          }

          let itemAddRows = [];
          for (let i = 1; i < subArrs.length; i++) {
            let tr = subArrs[i];
            let blanks = [];
            if (tr.length < this.state.generate.itemsMaxQty) {
              for (let j = 0; j < (this.state.generate.itemsMaxQty - tr.length); j++) {
                blanks.push((<td key={j}>&nbsp;</td>));
              }
            }
            itemAddRows.push(
              <tr key={i}>
                <td></td>
                <td></td>
                {tr.map((sub, subIndex) => {
                  return (
                    <td key={subIndex}>{(sub.num) + 'th'}</td>
                  );
                })}
                {blanks.map(td => td)}
                <td></td>
              </tr>
            );
            itemAddRows.push(
              <tr key={i + '_data'}>
                <td></td>
                <td></td>
                {tr.map((sub, subIndex) => {
                  if(this.state.generate.items[itemIndex].is_editing) {
                    return (
                      <td key={subIndex}>
                        <input type="text" className="form-control my-field" defaultValue={sub.name} placeholder={(sub.num) + 'th'} onChange={(e) => {
                          let generate = this.state.generate;
                          let fSub = generate.items[itemIndex].sub;
                          for(let fi in fSub) {
                            if(sub.num==fSub[fi].num) {
                              fSub[fi].name = e.target.value;
                              break;
                            }
                          }
                          generate.items[itemIndex].sub = fSub;
                          this.setState({generate: generate});
                        }} />
                      </td>
                    );
                  }
                  return (
                    <td key={subIndex}>
                      {sub.name}
                    </td>
                  );
                })}
                {blanks.map(td => td)}
                <td></td>
              </tr>
            );
          }

          let qtyField = (<span>{item.qty}</span>);
          if(this.state.generate.items[itemIndex].is_editing) {
            qtyField = (<input type="text" className="form-control my-field" defaultValue={item.qty} placeholder={'e.g ' + item.qty} />);
          }

          let actioBtn = (
            <button className="btn btn-warning my-btn" style={{ float: 'right' }} onClick={() => {
              let generate = this.state.generate;
              let items = generate.items;
              items[itemIndex].is_editing = true;
              /*
              delete items[itemIndex];
              let biggestQty = 0;
              for (let i = 0; i < items.length; i++) {
                if (items[i]) {
                  if (items[i].sub.length > biggestQty) biggestQty = items[i].sub.length;
                }
              }
              generate.itemsBiggestQty = biggestQty;
              */
              this.setState({ generate: generate });
              this.popupRefresh(this.popupTemplateItems());
            }}>
              Edit
            </button>
          );
          if(this.state.generate.items[itemIndex].is_editing) {
            actioBtn = (
              <button className="btn btn-info my-btn" style={{ float: 'right' }} onClick={() => {
                let generate = this.state.generate;
                let items = generate.items;
                delete items[itemIndex].is_editing;
                this.setState({ generate: generate });
                this.popupRefresh(this.popupTemplateItems());
              }}>
                Go
              </button>
            );
          }

          return (
            <tbody key={itemIndex}>
              <tr className="trh">
                <td>{itemIndex + 1}</td>
                <td>Total {item.name}</td>
                {subArrs[0].map((sub, subIndex) => {
                  return (
                    <td key={subIndex}>{(sub.num) + 'th'}</td>
                  );
                })}
                {blanksMain.map(td => td)}
                <td style={{ width: '30px' }}>
                  {actioBtn}
                </td>
              </tr>
              <tr className="trd">
                <td></td>
                <td>
                  {qtyField}
                </td>
                {subArrs[0].map((sub, subIndex) => {
                  if(this.state.generate.items[itemIndex].is_editing) {
                    return (
                      <td key={subIndex}>
                        <input type="text" className="form-control my-field" defaultValue={sub.name} placeholder={(sub.num) + 'th'} onChange={(e) => {
                          let generate = this.state.generate;
                          let fSub = generate.items[itemIndex].sub;
                          for(let fi in fSub) {
                            if(sub.num==fSub[fi].num) {
                              fSub[fi].name = e.target.value;
                              break;
                            }
                          }
                          generate.items[itemIndex].sub = fSub;
                          this.setState({generate: generate});
                        }} />
                      </td>
                    );
                  }
                  return (
                    <td key={subIndex}>
                      {sub.name}
                    </td>
                  );
                })}
                {blanksMain.map(td => td)}
                <td></td>
              </tr>
              {itemAddRows.map(tr => tr)}
            </tbody>
          );
        })}
        {addItem}
        {submitBtnCont}
      </table>
    );
  };

  popupRefresh = (content) => {
    let popup = this.state.popup;
    popup.content = content;
    this.setState({popup:popup});
  };

  getTemplateJson = () => {
    let items = {...this.state.generate.items};
    let orgItems = [...this.state.generate.items];
    let months = null;
    let dates = null;
    for(let i in items) {
      let item = items[i];
      let search = (item.name+'').toLowerCase();
      if(search.search('month')>=0 || search.search('maas')>=0) {
        months = item;
        delete items[i];
      }
      else if(search.search('date')>=0 || search.search('tithi')>=0) {
        dates = item;
        delete items[i];
      }
    }

    let monthValues = [];
    for(let i=0; i<months.length; i++) {
      monthValues.push(''+(i+1));
    }

    let json = {
      code: '',
      filter: {
        labels: [...months.sub.map(_ => _.name)],
        values: monthValues,
        mapped_column: 1,
        selected: '1',
        type: 'simple'
      },
      generate: {
        months: [...months.sub.map(_ => _.name)],
        dates: [...dates.sub.map(_ => _.name)]
      },
      columns: [],
      attrs: orgItems
    };

    json.columns.push({
      label: 'MonthNo',
      col: 1,
      type: 'text',
      edit: 'false',
      copy: 'true',
      hide: 'true',
      negative: 'false'
    });

    json.columns.push({
      label: months.name,
      col: 2,
      type: 'text',
      edit: 'false',
      copy: 'true',
      hide: 'false',
      negative: 'false'
    });

    json.columns.push({
      label: 'DateNo',
      col: 3,
      type: 'text',
      edit: 'false',
      copy: 'true',
      hide: 'true',
      negative: 'false'      
    });

    json.columns.push({
      label: dates.name,
      col: 4,
      type: 'text',
      edit: 'false',
      copy: 'true',
      hide: 'false',
      negative: 'false'      
    });

    json.columns.push({
      label: 'EventName',
      col: 5,
      type: 'text',
      edit: 'true',
      copy: 'true',
      hide: 'false',
      negative: 'false'
    });

    let cols = 6;
    for(let i in items) {
      json.columns.push({
        label: items[i].name,
        col: cols,
        type: 'text',
        edit: 'true',
        copy: 'true',
        hide: 'false',
        negative: 'false',
      });
      cols += 1;
    }
    //console.log(json);
    return json;
  };

  saveCategory = (req) => {
    HttpUtil
      .postJson(Api.combinationSaveCategories, req)
      .then(result => {
        try {
          if(result.meta.status===0) {
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  }

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  loadCalendarFormat = (d) => {
    console.log(d);
    HttpUtil
      .getJson(Api.genericCalendarFormat+d.file)
      .then(result => {
        let items = [];
        try {
          if(result.meta.status===0) {
            for(let i in result.data.attrs) {
              let d = result.data.attrs[i];
              let sub = [];
              for(let j in d.sub) {
                sub.push({
                  key: d.sub[j].key,
                  name: d.sub[j].value,
                  num: parseInt(j)+1
                })
              }
              items.push({
                type: d.type,
                name: d.name,
                qty: d.sub.length,
                sub: sub
              });
            }
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
          alert('Could not load data');
        }

        let generate = this.state.generate;
        generate.items = items;

        let popup = this.state.popup;
        popup.show = true;
        popup.size = 10;
        popup.head = 'Add Items - '+d.country+' ('+d.state+') - '+d.base+'';
        popup.content = this.popupTemplateItems();
        this.setState({popup:popup, selected: d, generate: generate});
      });
  }

  loadCategories = (d) => {
    HttpUtil
      .getJson(Api.combinationGetCategories+'?templateId='+d.id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let req = {...d};
            req.categoryId = 0;
            for(let i in result.data) {
              req.categoryId = result.data[i].id;
              break;
            }
            this.getTemplateFormat(req);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  getTemplateFormat = (d) => {
    let json = JSON.parse(d.info);

    let popup = this.state.popup;
    popup.show = true;
    popup.size = 10;
    popup.head = 'Preview - '+d.country+' ('+d.category+') - '+d.base+'';

    let rows = [];
    for(let i in json.generate.months) {
      let _m = json.generate.months[i];
      for(let j in json.generate.dates) {
        let _d = json.generate.dates[j];
        rows.push((
          <tr key={i+'_'+j}>
            <td>{parseInt(i)+1}</td>
            <td>{_m}</td>
            <td>{parseInt(j)+1}</td>
            <td>{_d}</td>
          </tr>
        ));
      }
    }

    popup.content = (
      <div>
        <div style={{overflow:'auto', maxHeight:'500px'}}>
          <table className="table table-bordered myTable" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th>Month No</th>
                <th>Month Name</th>
                <th>Date No</th>
                <th>Date Name</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((tr, index) => tr)}
            </tbody>
          </table>
        </div>
        <div className="form-group" style={{textAlign:'center'}}>
          <a className="btn btn-primary my-btn" target="blank" href={'/calendar/'+d.id+'/'+d.categoryId}>
            Next
          </a>
        </div>
      </div>
    );
    this.setState({popup:popup});
  }

  uploadExcel = (e) => {
    if(!this.state.excel.file) return;
    let formData = new FormData();
    formData.append('file', this.state.excel.file);
    HttpUtil
      .postFormData(Api.uploadExcel, formData)
      .then(result => {
        try {
          if(result.data) {
            if(result.data.length>0) {
              let cols = [];
              for(let k in result.data[0]) {
                cols.push({
                  key: k,
                  value: result.data[0][k]
                });
              }
              let rows = [];
              for(let i in result.data) {
                if(i==0) continue;
                let row = [];
                for(let k in result.data[i]) {
                  row.push({
                    key: k,
                    value: result.data[i][k]
                  });
                }
                rows.push(row);
              }
              let excel = this.state.excel;
              excel.cols = cols;
              excel.rows = rows;
              excel.selected = {};
              this.setState({excel:excel});

              setTimeout(() => {
                let popup = this.state.popup;
                popup.show = true;
                popup.size = 12;
                popup.head = 'Upload Excel - Preview';
                popup.content = this.uploadExcelPopup();
                this.setState({popup:popup});
              }, 0);
              //if(e.target && e.target.value) e.target.value = null;
            }
          }
          console.log(result);
          return;
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  uploadExcelPopup = () => {
    let excelPreviewCont = (
      <div className="form-group alert alert-warning">
        No data found. Please choose specific calendars.
      </div>
    );
    if(this.state.excel.cols.length>0) {
      excelPreviewCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              {this.state.excel.cols.map((th, thIndex) => {
                return (
                  <th key={thIndex}>
                    <input type="checkbox" className="my-field" onChange={(e) => {
                      let excel = this.state.excel;
                      if(e.target.checked) {
                        excel.selected[th.key] = {...th};
                      }
                      else {
                        delete excel.selected[th.key];
                      }
                      this.setState({excel: excel});
                    }} />
                  </th>
                );
              })}
            </tr>
            <tr>
              {this.state.excel.cols.map((th, thIndex) => {
                return (
                  <th key={thIndex}>{th.value}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.excel.rows.map((tr, trIndex) => {
              return (
                <tr key={trIndex}>
                  {tr.map((td, tdIndex) => {
                    return (
                      <td key={tdIndex}>{td.value}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return (
      <div>
        <div className="form-group" style={{maxHeight:'400px', overflow:'auto'}}>
          {excelPreviewCont}
        </div>
        <div className="form-group" style={{textAlign:'right'}}>
          <button type="button" className="btn btn-danger my-btn" onClick={() => {
            setTimeout(() => {
              let excel = this.state.excel;
              excel.file = null;
              excel.cols = [];
              excel.rows = [];
              excel.selected = {};

              let popup = this.state.popup;
              popup.show = false;
              this.setState({ popup: popup, excel: excel });
            }, 0);
          }}>
            Cancel
                </button>
          <button type="button" className="btn btn-success my-btn" onClick={() => {
            setTimeout(() => {
              let popup = this.state.popup;
              popup.show = false;
              this.setState({popup:popup});
            }, 0);
          }}>
            Submit
          </button>
        </div>
      </div>
    );
  };

  excelGetContentForSave = () => {
    let initData = [];
    let initCols = {
      'month': '-',
      'date': '-',
      'event': '-'
    };
    for(let k in this.state.excel.selected) {
      let c = this.state.excel.selected[k];
      if(c.value.toLocaleLowerCase().search('event')>=0) {
        initCols['event'] = c.key;
      }
      else if(c.value.toLocaleLowerCase().search('month')>=0) {
        initCols['month'] = c.key;
      }
      else if(c.value.toLocaleLowerCase().search('date')>=0) {
        initCols['date'] = c.key;
      }
    }
    for(let i in this.state.excel.rows) {
      let row = {};
      for(let j in this.state.excel.rows[i]) {
        let c = this.state.excel.rows[i][j];
        if(c.key==initCols['month']) {
          row['month'] = c.value;
        }
        else if(c.key==initCols['date']) {
          row['date'] = c.value;
        }
        else if(c.key==initCols['event']) {
          row['event'] = c.value;
        }
      }
      if(row['month'] && row['date']) initData.push(row);
    }
    return {
      contents: initData
    };
  };

  render = () => {
    let innerContent = (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <table style={{width:'100%'}}>
                <tbody>
                  <tr>
                    <td>
                      <h6 className="m-0 font-weight-bold text-primary">Make - New Event - Base International Calendar</h6>
                    </td>
                    <td style={{textAlign:'right'}}>
                      <input type="file" className="btn btn-info my-btn" onChange={(e) => {
                        let excel = this.state.excel;
                        excel.file = e.target.files[0];
                        this.setState({excel: excel});
                        this.uploadExcel(e);
                      }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered myTable" width="100%" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Calendar Base</th>
                    <th>Calendar Type</th>
                    <th>Calendar Sub Type 1</th>
                    <th>Calendar Sub Type 2</th>
                  </tr>
                  <tr>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarBase()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarType()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub1()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub2()}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <th>Calendar Category</th>
                    <th>Calendar Country</th>
                    <th>Calendar Name</th>
                    <th>&nbsp;</th>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" className="form-control my-field" placeholder="e.g General/English/Hindu"
                        onChange={(e) => {
                          let template = this.state.template;
                          template.category = e.target.value;
                          this.setState({template:template});
                        }} />
                    </td>
                    <td>
                      <input type="text" className="form-control my-field" placeholder="e.g India"
                        onChange={(e) => {
                          let template = this.state.template;
                          template.country = e.target.value;
                          this.setState({template:template});
                        }} />
                    </td>
                    <td>
                      <input type="text" className="form-control my-field" placeholder="e.g Fun Holidays etc"
                        onChange={(e) => {
                          let template = this.state.template;
                          template.name = e.target.value;
                          this.setState({template:template});
                        }} />
                    </td>
                    <td>
                      <button type="button" className="btn btn-primary btn-sm my-btn" style={{ float: "right" }} onClick={() => {
                        let req = {...this.state.template};
                        req.base_default = 'Default';
                        this.saveTemplateMaster(req);
                      }}>
                        Submit for Empty Template and Empty Event
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Existing Events</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th colSpan={7}>Calendar</th>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th>Sno</th>
                    <th>Country</th>
                    <th>Category</th>
                    <th>Name</th>
                    <th>Base</th>
                    <th>Type</th>
                    <th>Sub Type 1</th>
                    <th>Sub Type 2</th>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((d, index) => {
                    if(d.is_generated==1) {
                      return <tr key={index} />;
                    }
                    if(d.editing) {
                      return (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>
                            <input type="text" value={d.country} className="form-control my-field" onChange={(e) => {
                              let data = this.state.data;
                              data[index].country = e.target.value;
                              this.setState({data:data});
                            }} />
                          </td>
                          <td>
                            <input type="text" value={d.state} className="form-control my-field" onChange={(e) => {
                              let data = this.state.data;
                              data[index].state = e.target.value;
                              this.setState({data:data});
                            }} />
                          </td>
                          <td>
                            <input type="text" value={d.base} className="form-control my-field" onChange={(e) => {
                              let data = this.state.data;
                              data[index].base = e.target.value;
                              this.setState({data:data});
                            }} />
                          </td>
                          <td>
                            <input type="text" value={d.type} className="form-control my-field" onChange={(e) => {
                              let data = this.state.data;
                              data[index].type = e.target.value;
                              this.setState({data:data});
                            }} />
                          </td>
                          <td>
                            <input type="text" value={d.name} className="form-control my-field" onChange={(e) => {
                              let data = this.state.data;
                              data[index].name = e.target.value;
                              this.setState({data:data});
                            }} />
                          </td>
                          <td style={{width:'70px'}}>
                            <button type="button" className="btn btn-success my-btn" onClick={() => {
                              let req = this.state.data[index];
                              this.saveTemplateMaster(req);
                            }}>
                              Go
                            </button>
                            <button type="button" className="btn btn-danger my-btn" onClick={() => {
                              let req = this.state.data[index];
                              this.saveTemplateMaster(req);
                            }}>
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                          <td style={{width:'40px'}}>
                            <button type="button" className="btn btn-primary my-btn">
                              Next
                            </button>
                          </td>
                        </tr>
                      );  
                    }
                    return (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{d.country}</td>
                        <td>{d.category}</td>
                        <td>{d.name}</td>
                        <td>{d.base}</td>
                        <td>{d.type}</td>
                        <td>{d.typeSub1}</td>
                        <td>{d.typeSub2}</td>
                        <td style={{width:'60px'}}>
                          <button type="button" className="btn btn-warning my-btn" onClick={() => {
                            let data = this.state.data;
                            data[index].editing = true;
                            this.setState({data:data});
                          }}>
                            Edit
                          </button>
                          <button type="button" className="btn btn-danger my-btn" onClick={() => {
                              let req = this.state.data[index];
                              this.deleteTemplateMaster(req);
                            }}>
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        <td style={{width:'40px'}}>
                          <button type="button" className="btn btn-primary my-btn" onClick={() => {
                            this.loadCalendarFormat(d);
                          }}>
                            Next
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Open Events</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th colSpan={7}>Calendar</th>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th>Sno</th>
                    <th>Country</th>
                    <th>Category</th>
                    <th>Name</th>
                    <th>Base</th>
                    <th>Type</th>
                    <th>Sub Type 1</th>
                    <th>Sub Type 2</th>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((d, index) => {
                    if(d.is_generated==0) {
                      return <tr key={index} />;
                    }
                    return (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{d.country}</td>
                        <td>{d.category}</td>
                        <td>{d.name}</td>
                        <td>{d.base}</td>
                        <td>{d.type}</td>
                        <td>{d.typeSub1}</td>
                        <td>{d.typeSub2}</td>
                        <td style={{width:'40px'}}>
                          <button type="button" className="btn btn-primary my-btn" onClick={() => {
                            this.loadCategories(d);
                          }}>
                            Open
                          </button>                          
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>


        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />
      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardEventNew;
