import React, { Component } from 'react';

function Popup(params) {
  let props = params.props;
  let state = {
    status: props.status?props.status:false,
    opts: {
      size: props.opts.size?props.opts.size:6,
      head: props.opts.head?props.opts.head:'',
      content: props.opts.content?props.opts.content:'',
    },
    onHide: props.onHide
  };
  return (
    <div style={{position:'fixed',width:'100%',height:'100%',top:'0px',left:'0px',display:((state.status)?'block':'none'),zIndex:'999'}}>
      <div style={{width:'100%',height:'100%',background:'rgba(20,20,20,0.8)'}}>
        <div style={{padding:'80px 20px 20px 20px'}}>
          <div className="row">
            <div className={'col-lg-'+Math.floor((12 - state.opts.size)/2)}>&nbsp;</div>
            <div className={'col-lg-'+state.opts.size}>
              <div className="card shadow">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    <div style={{float:'left',fontSize:'14xpx'}}>{state.opts.head}</div>
                    <div style={{float:'right'}}>
                      <button type="button" className="btn btn-sm btn-danger my-btn" onClick={() => state.onHide()}>
                        Close <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </h6>
                </div>
                <div className="card-body">
                  {state.opts.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup
