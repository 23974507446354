import Formats from './data_formats';

class CommonUtil {

  static raashi(pos) {
    let x_ = pos;
		let x = Math.abs(pos);
		let z = Math.floor(x / 30);
		let d = Math.floor(x);
		let ss0 = Math.round((x - d) * 3600);
		let m = Math.floor(ss0 / 60);
		let s = (ss0 % 60) % 60;
		d %= 30;
    return {
      raashi: z+1,
      raashi_label: Formats.RAASHI(z+1).a,
      position: (d+'º '+m+"' "+s+'"'),
      position_val: [d, m, s]
    };
		//return array('raashi' => $z, 'position' => $d.'&deg;'.$m.'\''.$s.'"', 'position_val' => array($d, $m, $s), 'pos'=>$x_);
  }

}


export default CommonUtil
