import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardCompareDataList extends Component {

  state = {
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    primary: null,
    input: {
      year: '',
      month: '',
      date: '',
      event: {
        data: []
      }
    },
    data: [],
    compare: {},
    compareType: '',
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  };

  componentDidMount = () => {
    this.fetchCompileYearList();
  };

  fetchCompileYearList = () => {
    HttpUtil
      .getJson(Api.combinationCompileYearFetchAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = {};
            for(let i in result.data) {
              let d = result.data[i];
              let key = d.template_id+'_'+d.category_id;
              if(!data[key]) {
                data[key] = {
                  info: d,
                  years: []
                };
              }
              data[key].years.push(d);
            }
            //console.log(data);
            this.setState({data: data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  fetchEvents = () => {
    if(this.state.input.year==='' || this.state.input.month==='') {
      return;
    }
    let formData = new FormData();
    formData.append('templateId', this.state.primary.template_id);
    formData.append('categoryId', this.state.primary.category_id);
    formData.append('year', this.state.input.year);
    formData.append('month', this.state.input.month);
    formData.append('date', this.state.input.date);
    HttpUtil
      .postFormData(Api.combinationCompareFetch, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = [];
            for(let i in result.data.items) {
              let d = result.data.items[i];
              if(this.state.primary.template_id==d.template_id && this.state.primary.category_id==d.category_id && d.col_5+''!='') {
                data.push(d);
              }
            }
            let input = this.state.input;
            input.event.data = data;
            if(data.length==0) {
              alert('No events found for '+this.state.input.month+' / '+this.state.input.year);
              input.month = '';
            }
            let popup = this.state.popup;
            popup.content = this.getPopupContent();

            this.setState({input: input, popup:popup});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  getPopupContent = () => {    
    let output = (<div />);

    let compare = [];
    for(let k in this.state.compare) {
      let d = this.state.compare[k];
      compare.push(d);
    }

    let typeCont = (<div />);
    if(this.state.compareType=='yearly') {
      typeCont = (
        <div className="form-group">
          <label>Enter Year/Samvat</label>
          <input type="number" className="form-control my-field" placeholder="e.g 2016" onChange={(e) => {
            let input = this.state.input;
            input.year = e.target.value;
            this.setState({input: input});
          }} />
        </div>
      );
    }
    else if(this.state.compareType=='monthly') {
      typeCont = (
        <div>
          <div className="form-group">
            <label>Enter Year</label>
            <input type="number" className="form-control my-field" placeholder="e.g 2016" onChange={(e) => {
              let input = this.state.input;
              input.year = e.target.value;
              this.setState({input: input});
            }} />
          </div>
          <div className="form-group">
            <label>Select Month</label>
            <select className="form-control my-field" onChange={(e) => {
              let input = this.state.input;
              input.month = e.target.value;
              this.setState({input: input});
            }}>
              <option value="">-- Select Month --</option>
              {this.state.months.map((d, index) => <option key={index} value={(index+1)}>{d}</option>)}
            </select>
          </div>
        </div>
      );
    }
    else if(this.state.compareType=='single') {
      typeCont = (
        <div>
          <div className="form-group">
            <label>Enter Year</label>
            <input type="number" className="form-control my-field" placeholder="e.g 2016" onChange={(e) => {
              let input = this.state.input;
              input.year = e.target.value;
              this.setState({input: input});
              this.fetchEvents();
            }} />
          </div>
          <div className="form-group">
            <label>Select Month</label>
            <select className="form-control my-field" onChange={(e) => {
              let input = this.state.input;
              input.month = e.target.value;
              this.setState({input: input});
              this.fetchEvents();
            }}>
              <option value="">-- Select Month --</option>
              {this.state.months.map((d, index) => <option key={index} value={(index+1)}>{d}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Select Event</label>
            <select className="form-control my-field" onChange={(e) => {
              let input = this.state.input;
              input.date = e.target.value;
              this.setState({input: input});
            }}>
              <option value="">-- Select Event --</option>
              {this.state.input.event.data.map((d, index) => <option key={index} value={d.col_3}>{d.col_5}</option>)}
            </select>
          </div>
        </div>
      );
    }
  
    return (
      <div className="my-cont">
        <div className="form-group">
          <label>Selected</label>
          <div style={{border:'1px dashed #ccc', lineHeight:'20px', padding:'2px', borderRadius:'5px'}}>
            {compare.map((d, index) => {
              return (
                <span key={index} style={{border:'1px solid #ccc', padding:'0px 5px', borderRadius:'10px', margin:'1px'}}>{d.category+' - '+d.country}</span>
              );
            })}
          </div>
        </div>
        <div className="form-group">
          <label>Compare Type</label>
          <select className="form-control my-field" onChange={(e) => {
            this.setState({compareType: e.target.value});
            setTimeout(() => {
              let popup = this.state.popup;
              popup.content = this.getPopupContent();
              this.setState({popup:popup});
            }, 0);
          }}>
            <option value="">-- Select Compare Type --</option>
            <option value="yearly">Full Year</option>
            <option value="monthly">Full Month</option>
            <option value="single">Single Event</option>
          </select>
        </div>
        {typeCont}
        <div className="form-group">
          <button className="btn btn-success my-btn" onClick={() => {
            if(this.state.input.year.trim()=='') {
              alert('Please enter year');
              return false;
            }
            let url = '/compare/'+this.state.primary.template_id+'/'+this.state.primary.category_id+'/'+this.state.input.year;
            if(this.state.input.month!=='') url = url + '/' + this.state.input.month;
            if(this.state.input.date!=='') url = url + '/' + this.state.input.date;
            window.location.href = url;
          }}>Submit</button>
        </div>
        {output}
      </div>
    );
  }

  render = () => {

    let generated = [];
    for(let k in this.state.data) {
      let d = this.state.data[k];
      generated.push(d);
    }

    let compare = [];
    for(let k in this.state.compare) {
      let d = this.state.compare[k];
      compare.push(d);
    }

    let compareBtn = (<span />);
    if(compare.length>0) {
      compareBtn = (
        <button className="btn btn-primary my-btn" style={{float:'right'}} onClick={() => {

          let popup = this.state.popup;
          popup.head = 'Compare';
          popup.content = this.getPopupContent();
          popup.show = true;

          this.setState({popup:popup});
        }}>Compare</button>
      );
    }

    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Compare List
                {compareBtn}
              </h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'120px'}}>Country</th>
                    <th style={{width:'120px'}}>Calendar Name</th>
                    <th>Years</th>
                    <th style={{width:'50px'}}>Compare</th>
                  </tr>
                </thead>
                <tbody>
                  {generated.map((cat, catIndex) => {
                    return (
                      <tr style={{background:'#fff'}} key={catIndex}>
                        <td>{cat.info.country}</td>
                        <td>{cat.info.category}</td>
                        <td>
                          {cat.years.map((d, index) => {
                            return (
                              <a key={index} className="btn my-btn" style={{background:'#fff',border:'1px solid #ccc'}}  target="blank" href={'/compile-year/'+d.template_id+'/'+d.category_id+'/'+d.year}>
                                {d.year}
                              </a>
                            );
                          })}
                        </td>
                        <td style={{textAlign:'center'}}>
                          <input type="checkbox" onChange={(e) => {
                            let compareData = this.state.compare;
                            let key = cat.info.template_id+'_'+cat.info.category_id;
                            if(e.target.checked) {
                              compareData[key] = cat.info;
                            }
                            else {
                              delete compareData[key];
                            }
                            let count = 0;
                            let primary = null;
                            for(let i in compareData) {
                              count += 1;
                              if(count>1) {
                                break;
                              }
                              primary = compareData[i];
                            }
                            console.log(primary);
                            this.setState({compare: compareData, primary: primary});
                          }} />
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardCompareDataList;
