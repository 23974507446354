import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardEventNew extends Component {

  state = {

    req: {
      templateId: this.props.params.templateId,
      categoryId: this.props.params.templateId,
      year: this.props.params.year,
      month: this.props.params.month?this.props.params.month:'',
      date: this.props.params.date?this.props.params.date:''
    },
    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    data: null
  };

  componentDidMount = () => {
    this.fetchCompileData();
  };

  fetchCompileData = () => {
    let formData = new FormData();
    formData.append('templateId', this.state.req.templateId);
    formData.append('categoryId', this.state.req.categoryId);
    formData.append('col1', this.state.req.month);
    console.log(formData);
    HttpUtil
      .postFormData(Api.combinationCompareFetch, formData)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            let data = [];
            for(let i in result.data.items) {
              let d = result.data.items[i];
              if(d.col_5+''!='' && d.c_year+''===this.state.req.year+'' && (this.state.req.month==='' || d.col_1===this.state.req.month)) {
                if(this.state.req.date==='' || d.col_3===this.state.req.date) {
                  data.push(d);
                }
              }
            }
            result.data.items = data;
            console.log(result.data);
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  render = () => {
    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-body my-cont">
              Loading data ...
          </div>
          </div>
        </div>

      </div>
    );

    if (this.state.data != null) {
      innerContent = (
        <div className="row">

          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Compare Preview
                  {' - '+this.state.data.category.label }
                </h6>
              </div>
              <div className="card-body my-cont">
                <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Year</th>
                      <th>Month</th>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Event Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.items.map((d, index) => {
                      let dt = new Date(d.c_date);
                      return (
                        <tr key={index}>
                          <td>{d.country}</td>
                          <td>{d.c_year}</td>
                          <td>{d.col_2}</td>
                          <td>{d.col_4}</td>
                          <td>{this.state.days[dt.getDay()]}</td>
                          <td>{d.col_5}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      );
    }

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardEventNew;
