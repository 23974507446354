import React from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory, useParams} from 'react-router-dom';

import Combination from './../pages/combination';
import CompileData from './../pages/dashboard/compile_data';
import CompileYearData from './../pages/dashboard/compile_year_data';
import CompareData from './../pages/dashboard/compare_data';
import Compare from './../pages/compare';
import DashboardEventCombinationData from '../pages/dashboard/event_combination_data';
import DashboardValidateTithiData from '../pages/dashboard/validation_tithi_data';
import DashboardValidateEventData from '../pages/dashboard/validation_event_data';


export class AppRouteUtil {

  static redirect(url) {
    let history = useHistory();
    history.push(url);
  }

}

function MyCombination() {
  let {calendar, categoryId} = useParams();
  return <Combination params={{calendar:calendar, categoryId:categoryId}} />
}

function MyEventCombination() {
  let {templateId: templateId, categoryId} = useParams();
  return <DashboardEventCombinationData params={{templateId:templateId, categoryId:categoryId}} />
}

function MyCompare() {
  let {calendar, categoryId} = useParams();
  return <Compare params={{calendar:calendar, categoryId:categoryId}} />
}

function MyCompileData() {
  let {templateId, categoryId, col1} = useParams();
  return <CompileData params={{templateId:templateId, categoryId:categoryId, col1:col1}} />
}

function MyCompareData() {
  let {templateId, categoryId, year, month, date} = useParams();
  return <CompareData params={{templateId:templateId, categoryId:categoryId, year:year, month:month, date:date}} />
}

function MyCompileYearData() {
  let {templateId, categoryId, year, col1} = useParams();
  return <CompileYearData params={{templateId:templateId, categoryId:categoryId, year:year, col1:col1}} />
}

function DashboardValidateEventDataFunc() {
  let {templateId, categoryId, year, col1} = useParams();
  return <DashboardValidateEventData params={{templateId:templateId, categoryId:categoryId, year:year, col1:col1}} />
}

function DashboardValidateTithiDataFunc() {
  let {templateId, categoryId, year, col1} = useParams();
  return <DashboardValidateTithiData params={{templateId:templateId, categoryId:categoryId, year:year, col1:col1}} />
}

export const AppRouter = (props) => {
  /*
          <Route exact path="/compare/:calendar/:categoryId">
          <MyCompare />
        </Route>
        */


  return (
    <Router>
      <Switch>
        {props.data.map((component, index) => {
          return (
            <Route exact path={component.url} key={index}>
              {component.action}
            </Route>
          )
        })}
        <Route exact path="/calendar/:calendar/:categoryId">
          <MyCombination />
        </Route>
        <Route exact path="/event/:templateId/:categoryId">
          <MyEventCombination />
        </Route>

        <Route exact path="/compile/:templateId/:categoryId">
          <MyCompileData />
        </Route>
        <Route exact path="/compile/:templateId/:categoryId/:col1">
          <MyCompileData />
        </Route>

        <Route exact path="/compile-year/:templateId/:categoryId/:year">
          <MyCompileYearData />
        </Route>
        <Route exact path="/compile-year/:templateId/:categoryId/:year/:col1">
          <MyCompileYearData />
        </Route>

        <Route exact path="/validate-tithi/:templateId/:categoryId/:year">
          <DashboardValidateTithiDataFunc />
        </Route>
        <Route exact path="/validate-tithi/:templateId/:categoryId/:year/:col1">
          <DashboardValidateTithiDataFunc />
        </Route>

        <Route exact path="/validate-event/:templateId/:categoryId/:year">
          <DashboardValidateEventDataFunc />
        </Route>
        <Route exact path="/validate-event/:templateId/:categoryId/:year/:col1">
          <DashboardValidateEventDataFunc />
        </Route>
        
        <Route exact path="/compare/:templateId/:categoryId/:year">
          <MyCompareData />
        </Route>
        <Route exact path="/compare/:templateId/:categoryId/:year/:month">
          <MyCompareData />
        </Route>
        <Route exact path="/compare/:templateId/:categoryId/:year/:month/:date">
          <MyCompareData />
        </Route>
        <Route>
          <div>
            <center><h1>Not Found</h1></center>
          </div>
        </Route>
      </Switch>
    </Router>
  )
}
