import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import MasterTemplates from './../../configs/templates';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardCompile extends Component {

  state = {
    template: {
      default_base: 'Default / International English',
      country: '',
      category: '',
      name: '',
      base: '',
      type: '',
      typeSub1: '',
      typeSub2: '',
      file: ''
    },
    base: {
      data: MasterTemplates.DATA,
    },
    data: {
      all : {
        templates: [],
        categories: [],
      },
      filters : {
        templates: [],
        categories: [],
      },
    },
    compile: {
      status: 'start',
      data: [],
    },
    radio: {
      template: null,
      event: null,
    },
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
  };

  componentDidMount = () => {
    this.loadTemplatesAll();
    this.loadCategoriesAll();
  };

  loadTemplatesAll = () => {
    HttpUtil
      .getJson(Api.combinationTemplateMasterGet+'?templateType=event')
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = this.state.data;
            data.all.templates = result.data;
            this.setState({data:data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  loadCategoriesAll = () => {
    HttpUtil
      .getJson(Api.combinationGetCategoriesAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = this.state.data;
            data.all.categories = result.data;
            this.setState({data:data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  setFilters = () => {
    //console.log(this.state.data);
    let data = this.state.data;
    let templates = [];
    let categories = [];
    let templateIds = {};
    for(let i in data.all.templates) {
      let d = data.all.templates[i];
      if((d.base+'').search(this.state.template.base)<0) continue;
      if((d.type+'').search(this.state.template.type)<0 && this.state.template.type!='') continue;
      if((d.typeSub1+'').search(this.state.template.typeSub1)<0 && this.state.template.typeSub1!='') continue;
      if((d.typeSub2+'').search(this.state.template.typeSub2)<0 && this.state.template.typeSub2!='') continue;
      templates.push(d);
      templateIds[d.id] = true;
    }
    for(let i in data.all.categories) {
      let c = data.all.categories[i];
      if(templateIds[c.templateId]) 
      categories.push(c);
    }
    data.filters.templates = templates;
    data.filters.categories = categories;
    this.setState({data: data});
  }

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
        this.setFilters();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }



  getData = (filter) => {
    HttpUtil
      .getJson(Api.combinationGetMaster+'?categoryId='+this.state.radio.event+'&filterType=simple&filter='+filter)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            let req = [];
            let compiledMonth = null;
            for(let i in result.data.rows) {
              let r = {...result.data.rows[i]};
              delete r.items;
              r.masterRowId = r.id;
              r.sno = 0;
              r.templateId = this.state.radio.template;
              r.categoryId = this.state.radio.event;
              req.push(r);
              if(compiledMonth==null) compiledMonth = {...r};
              for(let j in result.data.rows[i].items) {
                let d = {...result.data.rows[i].items[j]};
                d.templateId = this.state.radio.template;
                d.categoryId = this.state.radio.event;
                req.push(d);                
              }
            }
            this.saveCompile(req, filter, compiledMonth);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  saveCompile = (data, filter, compiledMonth) => {
    let compile = this.state.compile;
    if(compiledMonth==null) {
      compile.status = 'done';
      this.setState({compile:compile});
      return;
    }
    HttpUtil
      .postJson(Api.combinationCompileSave, data)
      .then(result => {
        try {
          if(result.meta.status===0) {
            compile.data.push(compiledMonth);
            this.setState({compile:compile});

            let filterNum = parseInt(filter)+1;
            this.getData(filterNum);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  render = () => {

    let templateCont = (<div>No templates for selected calendar</div>);
    if(this.state.data.filters.templates.length>0) {
      templateCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>Calendar Country</th>
              <th>Calendar Category</th>
              <th>Calendar Base</th>
              <th>Calendar Type</th>
              <th>Calendar Sub Type 1</th>
              <th>Calendar Sub Type 2</th>
              <th style={{ width: '50px' }}>Selected</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.filters.templates.map((d, index) => {
              return (
                <tr key={index}>
                  <td>{d.country}</td>
                  <td>{d.category}</td>
                  <td>{d.base}</td>
                  <td>{d.type}</td>
                  <td>{d.typeSub1}</td>
                  <td>{d.typeSub2}</td>
                  <td>
                    <input type="radio" name="radio_template" onChange={(e) => {
                      let radio = this.state.radio;
                      radio.template = d.id;

                      let compile = this.state.compile;
                      compile.status = 'start';
                      this.setState({ radio: radio, compile: compile });
                    }} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    let eventCont = (<div>No events for selected calendar</div>);
    if(this.state.data.filters.categories.length>0) {
      eventCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>Category</th>
              <th style={{width:'50px'}}>Selected</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.filters.categories.map((d, index) => {
              return (
                <tr key={index}>
                  <td>{d.label}</td>
                  <td>
                    <input type="radio" name="radio_event_cat" onChange={(e) => {
                      let radio = this.state.radio;
                      radio.event = d.id;

                      let compile = this.state.compile;
                      compile.status = 'start';
                      this.setState({radio: radio, compile: compile});
                    }} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }



    let compileData = (<div />);
    if(this.state.compile.status=='progress' || this.state.compile.status=='done') {
      let url = '/compile/'+this.state.radio.template+'/'+this.state.radio.event;
      let progress = this.state.compile.status=='progress'?(
        <div className="text-primary">
          <i className="fa fa-spin fa-circle-notch"></i> Compiling months in progress ...
        </div>
      ):(<div className="text-success"><i className="fa fa-home"></i> Compile succesful ...</div>);
      let preview = (<div />);
      if(this.state.compile.status=='done') {
        preview = (
          <div style={{marginTop:'20px'}}>
            <a className="btn btn-success my-btn" target="blank" href={url}>Full Year Preview</a>
          </div>
        );
      }
      compileData = (
        <div>
          {progress}
          <div style={{marginTop:'20px'}}>
            {this.state.compile.data.map((d, index) => {
              return (
                <a key={index} className="btn btn-primary my-btn" target="blank" href={url+'/'+d.col1}>{d.col2}</a>
              );
            })}
          </div>
          {preview}
        </div>
      );
    }

    let compileCont = (<span />);
    if(this.state.radio.template!=null && this.state.radio.event!=null) {
      compileCont = (
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <div style={{textAlign:'center'}}>
                <button className="btn btn-success my-btn" onClick={() => {
                  let compile = this.state.compile;
                  compile.status = 'progress';
                  compile.data = [];
                  this.setState({compile:compile});
                  this.getData(1);
                }}>Compile</button>
              </div>
            </div>
            <div className="card-body my-cont">
              {compileData}
            </div>
          </div>
        </div>
      );
    }

    let innerContent = (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Choose Calendar</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered myTable" width="100%" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Calendar Base</th>
                    <th>Calendar Type</th>
                    <th>Calendar Sub Type 1</th>
                    <th>Calendar Sub Type 2</th>
                  </tr>
                  <tr>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarBase()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarType()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub1()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub2()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Template List</h6>
            </div>
            <div className="card-body my-cont">
              {templateCont}
            </div>
          </div>
        </div>
       
        <div className="col-lg-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Event List</h6>
            </div>
            <div className="card-body my-cont">
              {eventCont}
            </div>
          </div>
        </div>
        
        {compileCont}

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />
      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardCompile;
