import {Sessions,Session} from './../actions/session';

class HttpUtil {
  static getJson(url, params) {
    if(typeof(params)!=='undefined') url = url+'?'+(new URLSearchParams(params));
    return fetch(url, {
      method: 'get'
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postJson(url, request) {
    return fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request)
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postFormData(url, request) {
    return fetch(url, {
      method: 'post',
      headers: {
        //'Accept': 'application/json',
        //'Content-Type': 'application/json',
      },
      body: request
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postJsonWithToken(url, request) {
    return fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+Sessions.get(Session.tokenInfo.token),
      },
      body: JSON.stringify(request)
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postFormDataWithToken(url, request) {
    return fetch(url, {
      method: 'post',
      headers: {
        //'Accept': 'application/json',
        //'Content-Type': 'application/json',
        'Authorization': 'bearer '+Sessions.get(Session.tokenInfo.token),
      },
      body: request
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
}

export default HttpUtil
