export default class Api {
  //static url = 'http://encr.me:8088/api/v1/';
  //static url = 'http://api.nirwanji.com/api/v1/';
  static url = 'http://45.77.240.21:8088/api/v1/';
  //static url = 'http://localhost:8088/api/v1/';

  static login = this.url+'user/login';

  static genericGet = this.url+'generic/get';
  static genericGetNoCache = this.url+'generic/get/nocache';
  static genericCalendarFormat = this.url+'generic/calendar/';
  static festivalSave = this.url+'festival/save';
  static festivalGet = this.url+'festival/get';

  static combinationTemplateMasterSave = this.url+'combination/template/master';
  static combinationTemplateMasterGet = this.url+'combination/template/master';
  static combinationTemplateMasterDelete = this.url+'combination/template/master/delete/';
  static combinationGetCategories = this.url+'combination/categories';
  static combinationGetCategoriesAll = this.url+'combination/categories/all';
  static combinationSaveCategories = this.url+'combination/categories';

  static combinationGetMaster = this.url+'combination/get/master';
  static combinationGetDataRow = this.url+'combination/data-row/get';
  static combinationGetBlankDataRow = this.url+'combination/data-row/blank';
  static combinationPostAddDataRow = this.url+'combination/data-row/add';
  static combinationPostDeleteDataRow = this.url+'combination/data-row/delete';
  static combinationGetDataRowCounter = this.url+'combination/data-row/counter';

  static combinationCompileSave = this.url+'combination/compile/save';
  static combinationCompileFetch = this.url+'combination/compile/data';
  static combinationCompileFetchAll = this.url+'combination/compile/list';
  static saveCombination = this.url+'combination/compile/comb-save';
  
  static combinationCompileYearSave = this.url+'combination/compile-year/save';
  static combinationCompileYearFetch = this.url+'combination/compile-year/data';
  static combinationCompileYearFetchAll = this.url+'combination/compile-year/list';

  static combinationCompareFetch = this.url+'combination/compare/data';
  static combinationMasterYukta = this.url+'combination/master/yukta';
  static combinationMasterYuktaSave = this.url+'combination/master/yukta/save';


  static compareGet = this.url+'compare/get';

  static worldDb = this.url+'generic/world-db';
  static uploadExcel = this.url+'generic/excel';
}
