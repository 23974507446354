class CombinationData {
  static get(type) {
    let data = {
      'karmkaal': [
        ['', '--KarmKaal--'],
        ['kkd1', 'd1 प्रातः'],
      	['kkd2', 'd2 संगव'],
      	['kkd3', 'd3 मध्यांह'],
      	['kkd4', 'd4 उपरांह'],
      	['kkd5', 'd5 सायंह'],
      	['kkn1', 'n1 प्रदोष'],
      	['kkn2', 'n2 निशीथ'],
      	['kkn3', 'n3 महानिशीथ 4 VT'],
      	['kkn4', 'n4 निशीथ'],
      	['kkn5', 'n5 अरुणोदयी'],
      ],
      'vyapni': [
        ['', '--Vyapni--'],
      	['srise', 'सूर्योदयी'],
      	['sset', 'सूर्यास्त'],
      	['mrise', 'चंद्रोदयी'],
      	['mset', 'चन्द्रास्त'],
      	['midd', 'मध्यदिन'],
      	['midn', 'मध्यरात्रि'],
      	['vid_pur', 'पूर्विद्धा (<< तिथि)', 'पूर्विद्धा'],
      	['vid_par', 'परविद्धा (तिथि >>)', 'परविद्धा'],
      ],
      'muhurat': [
        ['', '--Muhurat--'],
        ['md0', 'd1 शिव'],
        ['md1', 'd2 सर्प'],
        ['md2', 'd3 मित्र'],
        ['md3', 'd4 पितृ'],
        ['md4', 'd5 वासु'],
        ['md5', 'd6 जल'],
        ['md6', 'd7 विश्वेदेव'],
        ['md7', 'd8 अभिजित'],
        ['md8', 'd9 ब्रह्मा'],
        ['md9', 'd10 इंद्रा'],
        ['md10', 'd11 इन्द्रगति'],
        ['md11', 'd12 राक्षस'],
        ['md12', 'd13 वरुण'],
        ['md13', 'd14 आर्यमा'],
        ['md14', 'd15 यम'],
        ['mn0', 'n1 शिव'],
        ['mn1', 'n2 अजपाद'],
        ['mn2', 'n3 अहिर्बुरधन'],
        ['mn3', 'n4 पूषा'],
        ['mn4', 'n5 अश्विनीकुमार'],
        ['mn5', 'n6 यम'],
        ['mn6', 'n7 अग्नि'],
        ['mn7', 'n8 ब्रह्मा'],
        ['mn8', 'n9 चन्द्रमा'],
        ['mn9', 'n10 अदिति'],
        ['mn10', 'n11 गुरु'],
        ['mn11', 'n12 विष्णु'],
        ['mn12', 'n13 सूर्य'],
        ['mn13', 'n14 त्वाष्ट्रा'],
        ['mn14', 'n15 वायु']
      ],
      'yog': [
        ['', '--Yog--'],
        ['0', '1 विष्कम्भ'],
        ['1', '2 प्रिति'],
        ['2', '3 आयुष्मान'],
        ['3', '4 सौभाग्य'],
        ['4', '5 शोभन'],
        ['5', '6 अतिगण्ड'],
        ['6', '7 सुकर्मा'],
        ['7', '8 धृति'],
        ['8', '9 शूल'],
        ['9', '10 गण्ड'],
        ['10', '11 वृद्धि'],
        ['11', '12 ध्रुव'],
        ['12', '13 व्याघात'],
        ['13', '14 हर्षण'],
        ['14', '15 वज्र'],
        ['15', '16 सिद्धि'],
        ['16', '17 व्यतिपात'],
        ['17', '18 वरीयान'],
        ['18', '19 परिघ'],
        ['19', '20 शिव'],
        ['20', '21 सिद्ध'],
        ['21', '22 साध्य'],
        ['22', '23 शुभ'],
        ['23', '24 शुक्ल'],
        ['24', '25 ब्रह्म'],
        ['25', '26 ऐन्द्र'],
        ['26', '27 वैधृति']
      ],
      'naksh': [
        ['', '--Naksh--'],
        ['0', '1 अश्विनी'],
        ['1', '2 भरणी'],
        ['2', '3 कृतिका'],
        ['3', '4 रोहिणी'],
        ['4', '5 मृगशिरा'],
        ['5', '6 आर्द्रा'],
        ['6', '7 पुनर्वसु'],
        ['7', '8 पुष्य'],
        ['8', '9 अश्लेषा'],
        ['9', '10 मघा'],
        ['10', '11 पू.फा'],
        ['11', '12 उ.फा'],
        ['12', '13 हस्त'],
        ['13', '14 चित्रा'],
        ['14', '15 स्वाति'],
        ['15', '16 विशाखा'],
        ['16', '17 अनुराधा'],
        ['17', '18 ज्येष्ठा'],
        ['18', '19 मूला'],
        ['19', '20 पू.षा'],
        ['20', '21 उ.षा'],
        ['21', '22 अभिजित'],
        ['22', '23 श्रवण'],
        ['23', '24 धनिष्ठा'],
        ['24', '25 शतभिषा'],
        ['25', '26 पू.भा'],
        ['26', '27 उ.भा'],
        ['27', '28 रेवती']
      ],
      'nakshCharan': [
        ['', '--NakshCharan--'],
        ['0', '1'],
        ['1', '2'],
        ['2', '3'],
        ['3', '4']
      ],
      'lagn': [
        ['', '--Lagn--'],
        ['0', '1 मेष'],
        ['1', '2 वृषभ'],
        ['2', '3 मिथुन'],
        ['3', '4 कर्क'],
        ['4', '5 सिंह'],
        ['5', '6 कन्या'],
        ['6', '7 तुला'],
        ['7', '8 वृश्चिक'],
        ['8', '9 धनु'],
        ['9', '10 मकर'],
        ['10', '11 कुम्भ'],
        ['11', '12 मीन']
      ],
      'karan': [
        ['', '--Karan--'],
        ['0', '1 बव'],
        ['1', '2 बालव'],
        ['2', '3 कौलव'],
        ['3', '4 तैंतिल'],
        ['4', '5 गर'],
        ['5', '6 वणिज'],
        ['6', '7 विष्टि'],
        ['7', '8 शकुनि'],
        ['8', '9 चतुष्पद'],
        ['9', '10 नाग'],
        ['10', '11 किंस्तुघ्न']
      ],
      'vaar': [
        ['', '--Vaar--'],
        ['0', '1 रविवार'],
        ['1', '2 सोमवार'],
        ['2', '3 मंगलवार'],
        ['3', '4 बुद्धवार'],
        ['4', '5 गुरुवार'],
        ['5', '6 शुक्रवार'],
        ['6', '7 शनिवार']
      ]
    };
    if(data[type]) return data[type];
    return [
      ['', 'NO DATA']
    ];
  }

  static subSno(primarySno, secondarySno) {
    let series = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    if(secondarySno<=26) return primarySno+''+series[secondarySno-1];
  }
}

export default CombinationData;
