class MasterTemplates {
    static DATA = [
        {
            base: 'English',
            type: 'NA',
            typeSub1: 'NA',
            typeSub2: 'NA',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Lunar',
            typeSub1: 'Nirayan',
            typeSub2: 'Purnimaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Lunar',
            typeSub1: 'Nirayan',
            typeSub2: 'Amaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Lunar',
            typeSub1: 'Sayan',
            typeSub2: 'Purnimaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Lunar',
            typeSub1: 'Sayan',
            typeSub2: 'Amant',
            file: 'eng_na_na_na'
        },


        {
            base: 'Vikram Samvat',
            type: 'Solar',
            typeSub1: 'Nirayan',
            typeSub2: 'Purnimaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Solar',
            typeSub1: 'Nirayan',
            typeSub2: 'Amaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Solar',
            typeSub1: 'Sayan',
            typeSub2: 'Purnimaant',
            file: 'eng_na_na_na'
        },
        {
            base: 'Vikram Samvat',
            type: 'Solar',
            typeSub1: 'Sayan',
            typeSub2: 'Amant',
            file: 'eng_na_na_na'
        },

        {
            base: 'Hijri Samvat',
            type: 'NA',
            typeSub1: 'NA',
            typeSub2: 'NA',
            file: 'eng_na_na_na'
        },
        {
            base: 'Shaka Samvat',
            type: 'NA',
            typeSub1: 'NA',
            typeSub2: 'NA',
            file: 'eng_na_na_na'
        },
    ];
}
export default MasterTemplates;