class MasterData {

  static CHARS = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

  static MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  static PLANET = ['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Rahu', 'Ketu', 'Uranus', 'Neptune', 'Pluto'];
  static PLANET_EN = ['Sury', 'Chndr', 'Mangl', 'Budh', 'Guru', 'Shukr', 'Shani', 'Rahu', 'Ketu', 'Uranus', 'Neptune', 'Pluto'];

  static RAASHI = ['मेष','वृषभ','मिथुन','कर्क','सिंह','कन्या','तुला','वृश्चिक','धनु','मकर','कुम्भ','मीन'];
  static RITU = ['वसंत', 'ग्रीष्म', 'वर्षा', 'शरद', 'हेमंत', 'शिशिर'];
  static MAAS = ['चैत्र', 'वैशाख', 'ज्येष्ठ', 'आषाढ़', 'श्रावण', 'भादों', 'आश्विन', 'कार्तिक', 'मार्गशीर्ष', 'पौष', 'माघ', 'फाल्गुन'];

  static TITHI_SHUKL = ['प्रतिपदा', 'द्वितीया', 'तृतीया', 'चतुर्थी', 'पंचमी', 'षष्ठी', 'सप्तमी', 'अष्टमी', 'नवमी', 'दशमी', 'एकादशी', 'द्वादशी', 'त्रयोदशी', 'चतुर्दशी', 'पूर्णिमा'];
  static TITHI_SHUKL_SHORT = ['प्र.प', 'द्विती', 'तृती', 'चतु', 'पंच', 'षष्ठी', 'सप्त', 'अष्ट', 'नव', 'दश', 'एका', 'द्वा', 'त्रयो', 'चतुर्द', 'पूर्णि'];

  static TITHI_KRISHN = ['प्रतिपदा', 'द्वितीया', 'तृतीया', 'चतुर्थी', 'पंचमी', 'षष्ठी', 'सप्तमी', 'अष्टमी', 'नवमी', 'दशमी', 'एकादशी', 'द्वादशी', 'त्रयोदशी', 'चतुर्दशी', 'अमावस'];
  static TITHI_KRISHN_SHORT = ['प्र.प', 'द्विती', 'तृती', 'चतु', 'पंच', 'षष्ठी', 'सप्त', 'अष्ट', 'नव', 'दश', 'एका', 'द्वा', 'त्रयो', 'चतुर्द', 'अमा'];

  static NAKSHATRA = ['अश्विनी', 'भरणी', 'कृतिका', 'रोहिणी', 'मृगशिरा', 'आर्द्रा', 'पुनर्वसु', 'पुष्य', 'अश्लेषा', 'मघा', 'पू.फा', 'उ.फा', 'हस्त', 'चित्रा', 'स्वाति', 'विशाखा', 'अनुराधा', 'ज्येष्ठा', 'मूला', 'पू.षा', 'उ.षा',
    'श्रवण', 'धनिष्ठा', 'शतभिषा', 'पू.भा', 'उ.भा', 'रेवती'];
  static NAKSHATRA_SHORT = ['अश्वि', 'भरणी', 'कृति', 'रोहि.', 'मृग.', 'आर्द्रा', 'पुनर्व.', 'पुष्य', 'अश्ले.', 'मघा', 'पू.फा', 'उ.फा', 'हस्त', 'चित्रा', 'स्वा.', 'विशा.', 'अनु.', 'ज्येष्ठा', 'मूला', 'पू.षा', 'उ.षा', 'श्रवण', 'धनि.', 'शत.', 'पू.भा', 'उ.भा', 'रेवती'];

  static NAKSHATRA_28 = ['अश्विनी', 'भरणी', 'कृतिका', 'रोहिणी', 'मृगशिरा', 'आर्द्रा', 'पुनर्वसु', 'पुष्य', 'अश्लेषा', 'मघा', 'पू.फा', 'उ.फा', 'हस्त', 'चित्रा', 'स्वाति', 'विशाखा', 'अनुराधा', 'ज्येष्ठा', 'मूला', 'पू.षा', 'उ.षा', 'अभिजित',
  'श्रवण', 'धनिष्ठा', 'शतभिषा', 'पू.भा', 'उ.भा', 'रेवती'];
  static NAKSHATRA_28_SHORT = ['अश्वि', 'भरणी', 'कृति', 'रोहि.', 'मृग.', 'आर्द्रा', 'पुनर्व.', 'पुष्य', 'अश्ले.', 'मघा', 'पू.फा', 'उ.फा', 'हस्त', 'चित्रा', 'स्वा.', 'विशा.', 'अनु.', 'ज्येष्ठा', 'मूला', 'पू.षा', 'उ.षा', 'अभिजित', 'श्रवण', 'धनि.', 'शत.', 'पू.भा', 'उ.भा', 'रेवती'];

  static VAAR = ['रविवार', 'सोमवार', 'मंगलवार', 'बुद्धवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'];
  static VAAR_SHORT = ['रवि', 'सोम', 'मंगल', 'बुद्ध', 'गुरु', 'शुक्र', 'शनि'];

  static YOG = ['विष्कम्भ', 'प्रिथि', 'आयुष्मान', 'सौभाग्य', 'सोभना', 'अतिगण्ड', 'सुकर्माण', 'धृति', 'शूल', 'गण्ड', 'वृद्धि', 'ध्रुव', 'व्याघात', 'हर्षण', 'वज्र', 'सिद्धि', 'व्यतिपात', 'वरियाँ', 'परिघा', 'शिव', 'सिद्धा', 'साध्य', 'शुभ', 'शुक्ल', 'ब्रह्मा', 'इंद्र', 'वैधृति'];

  static KARAN = ['बव', 'बालव', 'कौलव', 'तैतुल', 'गरिज', 'वणिज', 'विष्टि', 'शकुन', 'चतुष्पद', 'नाग', 'किंस्तुघ्न'];

  static CHOGHADIYA = ['अमृत', 'शुभ', 'लाभ', 'चर', 'रोग', 'काल', 'उद्वेग'];

  static HORA = ['सूर्य', 'चंद्र', 'मंगल', 'बुद्ध', 'गुरु', 'शुक्र', 'शनि'];
  static HORA_SHORT = ['सू', 'चं', 'मं', 'बु', 'गु', 'शु', 'श'];

  static KAAL_DAY = ['प्रातः', 'संगव', 'मध्यां', 'अपरांह', 'सायं'];
  static KAAL_NIGHT = ['प्रदोष', 'निशीथ', 'महानिशीथ', 'गहनिंद्रा', 'अरुणोदय'];

}


export default MasterData;
