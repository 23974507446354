import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';


class DashboardHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    let innerContent = (
      <div className="row">
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/calendar/new" className="card-body">
              Make<br />
              New<br />
              Template
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/event/new" className="card-body">
              Make<br />
              New<br />
              EventList
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/calendar/event/compile" className="card-body">
              Compile<br />
              Empty<br />
              Template
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/compile/list" className="card-body">
              Open<br />
              Compiled<br />
              List
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/combination" className="card-body">
              Make<br />
              Tithi & Event<br />
              Combination
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/generate/list" className="card-body">
              Generate<br />
              Template<br />
              List
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/validation" className="card-body">
              Validate<br />
              Tithi & Event<br />
              List
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/generate/list/multiple" className="card-body">
              Generate<br />
              Multiple Years<br />
              List
            </a>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card shadow mb-4">
            <a href="/compare/list" className="card-body">
              Compare<br />
              Event<br />
              List
            </a>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">&nbsp;</h6>
            </div>
            <div className="card-body">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  }
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardHome;
