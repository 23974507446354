import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import Formats from './../utils/data_formats';
import CombinationData from './../components/combination_data';

import Popup from './../components/common/popup_new';

class CombinationLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryValue: null,
      categoryMake: false,
      popup: <Popup />,
      popupProps: {
        status: false,
        opts: {
          size: 4,
          head: '',
          content: '',
        },
        onHide: () => {
          let popupProps = this.state.popupProps;
          popupProps.status = false;
          this.setState({popupProps:popupProps});
        }
      },
    };
  }

  categoriesPopup = (template) => {
    let popupProps = this.state.popupProps;
    popupProps.opts.head = (
      <div style={{float:'left'}}>
        <span>{template}</span><br />
        <span style={{fontSize:'10px',color:'#999'}}>Create or Open Category</span>
      </div>
    );

    let categoryMaker = <div />;
    if(this.state.categoryMake) {
      categoryMaker = (
        <div style={{ marginTop: '10px' }}>
          <input type="text" className="form-control my-field"
            onChange={(e) => this.setState({ categoryValue: e.target.value })}
            placeholder="Enter Category Name"
            style={{ marginBottom: '5px' }} />
          <button className="btn btn-success my-btn" onClick={() => {
            this.saveCategory({
              templateId: template,
              label: this.state.categoryValue
            });
          }}>Save</button>
          <button className="btn btn-danger my-btn" onClick={() => {
            this.setState({categoryMake:false});
            this.categoriesPopup(template);
          }}>Cancel</button>
        </div>
      );
    }

    popupProps.opts.content = (
      <div>
        <div style={{marginTop:'0px'}}>
          <button className="btn btn-primary my-btn" onClick={() => {
            this.setState({categoryMake:true});
            this.categoriesPopup(template);
          }}>Make Category</button>
        </div>
        {categoryMaker}
        <div style={{marginTop:'10px'}}>
          <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
            <tbody>
              {this.state.categories.map((d, index) => {
                return (
                  <tr key={index}>
                    <td>{d.label}</td>
                    <td style={{ width: '50px' }}>
                      <a href={'/calendar/' + template + '/' + d.id} target="blank" className="btn btn-success my-btn">Open</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
    popupProps.status = true;
    this.setState({popupProps: popupProps});
  };

  saveCategory = (req) => {
    HttpUtil
      .postJson(Api.combinationSaveCategories, req)
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.setState({categoryValue:''});
            this.loadCategories(req.templateId);
            alert('Saved');
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  }

  loadCategories = (template) => {
    HttpUtil
      .getJson(Api.combinationGetCategories+'?templateId='+template)
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.setState({categories: result.data, categoryMake: false});
            this.categoriesPopup(template);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  render () {
    let innerContent = (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <span className="m-0 font-weight-bold text-primary" style={{float:'left'}}>Click On Links</span>
            </div>
            <div className="card-body">
              <a href="#" onClick={() => {
                  this.categoriesPopup(1);
                  this.loadCategories(1);
                }} className="btn btn-primary my-btn">ChandraMaas</a>
              <a href="#" onClick={() => {
                  this.categoriesPopup(2);
                  this.loadCategories(2);
                }} className="btn btn-primary my-btn">Western</a>
            </div>
          </div>
        </div>
        <Popup props={this.state.popupProps} />
      </div>
    );
    return innerContent
  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Festival - Add</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default CombinationLinks
