import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardCompileDataList extends Component {

  state = {
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    input: {
      templateId: null,
      categoryId: null,
      year: '',
      loc: null,
      userFullName: '',
      userEmail: '',
    },
    selected: null,    
    data: [],
    generated: [],
    generate: {
      status: 'start',
      done: []
    },
    worldDb : {      
      status: false,
      field: '',
      data: [],
      selected: null
    },
    step: 1,
  };

  componentDidMount = () => {
    this.fetchCompileList();
  };

  fetchCompileList = () => {
    HttpUtil
      .getJson(Api.combinationCompileFetchAll)
      .then(result => {
        try {
          if(result.meta.status===0) {
            this.setState({data: result.data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  render = () => {

    let generated = [];
    for(let k in this.state.generated) {
      let d = this.state.generated[k];
      generated.push(d);
    }

    let innerContent = (
      <div className="row">

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Compiled Data - List</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>State</th>
                    <th>Calendar Base</th>
                    <th>Calendar Type</th>
                    <th>Calendar Zone</th>
                    <th>Calendar Name</th>
                    <th style={{width:'80px'}}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>{d.country}</td>
                        <td>{d.state}</td>
                        <td>{d.base}</td>
                        <td>{d.type}</td>
                        <td>{d.name}</td>
                        <td>{d.category}</td>
                        <td>
                          <a className="btn btn-primary btn-sm my-btn" target="blank" href={'/compile/'+d.template_id+'/'+d.category_id}>
                            View Data
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>  
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />

      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardCompileDataList;
