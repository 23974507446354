import React from 'react';
import {Session,Sessions} from './actions/session';
import {AppRouter, AppRouteUtil} from './actions/routes';

import Login from './pages/login';
import Dashboard from './pages/dashboard';
import DashboardHome from './pages/dashboard/home';
import DashboardCalendarNew from './pages/dashboard/calendar_new';
import DashboardEventNew from './pages/dashboard/event_new';
import DashboardCollectiveDataNew from './pages/dashboard/collective_data_new';
import DashboardCollectiveDataOpen from './pages/dashboard/collective_data_open';
import DashboardCollectiveDataUpload from './pages/dashboard/collective_data_upload';
import DashboardCompile from './pages/dashboard/calendar_event_compile';
import DashboardCompileDataList from './pages/dashboard/compile_list';
import DashboardCompareDataList from './pages/dashboard/compare_list';

import MasterData from './utils/data';
import Step1 from './pages/step1';
import Step1New from './pages/step1_new';
import Step2 from './pages/step2';
import Step2New from './pages/step2_new';
import Step3 from './pages/step3';
import Step3New from './pages/step3_new';
import Step3Sample from './pages/step3_sample';
import Step4 from './pages/step4';
import Step4New from './pages/step4_new';
import Step5 from './pages/step5';
import EphemerisHome from './pages/dashboard/ephemeris_home';

import CombinationLinks from './pages/combination_links';
import Combination from './pages/combination';
import DashboardGeneratedDataOpen from './pages/dashboard/generated_data_open';
import ValidationHome from './pages/dashboard/validation_home';
import DashboardValidationTithi from './pages/dashboard/validation_tithi';
import DashboardValidationEvent from './pages/dashboard/validation_event';
import DashboardGenerateList from './pages/dashboard/generate_list';
import CombinationHome from './pages/dashboard/combination_home';
import CombinationEvent from './pages/dashboard/combination_event';
import CombinationTithi from './pages/dashboard/combination_tithi';
import DashboardGenerateListMultiple from './pages/dashboard/generate_list_multiple';

const SessionCheck = (props) => {
  let id = Sessions.get(Session.id);
  if(typeof(id)=='undefined' || id==null) return props.data.error;
  return props.data.success;
}

const SessionLogout = (props) => {
  Sessions.clear();
  AppRouteUtil.redirect('/login');
  return (
    <Login />
  )
}

const Redirect = (props) => {
  AppRouteUtil.redirect(props.url);
  return (
    <div />
  )
}

global.MasterData = MasterData;

export const App = () => {
  let appRoutes = [
    {
      url : '/step-1',
      action : <Step1 />
    },
    {
      url : '/step-1-new',
      action : <Step1New />
    },
    {
      url : '/step-2',
      action : <Step2 />
    },
    {
      url : '/step-2-new',
      action : <Step2New />
    },
    {
      url : '/step-3',
      action : <Step3 />
    },
    {
      url : '/step-3-new',
      action : <Step3New />
    },
    {
      url : '/sample',
      action : <Step3Sample />
    },
    {
      url : '/step-4',
      action : <Step4 />
    },
    {
      url : '/step-4-new',
      action : <Step4New />
    },
    {
      url : '/step-5',
      action : <Step5 />
    },
    {
      url : '/calendar/new',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCalendarNew />}} />
    },
    {
      url : '/event/new',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardEventNew />}} />
    },
    {
      url : '/collective-data/new',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCollectiveDataNew />}} />
    },
    {
      url : '/collective-data/open',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCollectiveDataOpen />}} />
    },
    {
      url : '/generated-data/open',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardGeneratedDataOpen />}} />
    },
    {
      url : '/calendar/event/compile',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCompile />}} />
    },
    {
      url : '/compile/list',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCompileDataList />}} />
    },
    {
      url : '/generate/list',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardGenerateList />}} />
    },
    {
      url : '/generate/list/multiple',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardGenerateListMultiple />}} />
    },
    {
      url : '/compare/list',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardCompareDataList />}} />
    },
    {
      url : '/calendar/links',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <CombinationLinks />}} />
    },
    {
      url : '/dashboard',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardHome />}} />
    },
    {
      url : '/ephemeris',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <EphemerisHome />}} />
    },
    {
      url : '/combination',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <CombinationHome />}} />
    },
    {
      url : '/combination/tithi',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <CombinationTithi />}} />
    },
    {
      url : '/combination/event',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <CombinationEvent />}} />
    },
    {
      url : '/validation',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <ValidationHome />}} />
    },
    {
      url : '/validation/tithi',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardValidationTithi />}} />
    },
    {
      url : '/validation/event',
      action : <SessionCheck data={{error: <Redirect url='/login' />, success : <DashboardValidationEvent />}} />
    },
    {
      url : '/login',
      action : <SessionCheck data={{error: <Login />, success : <Redirect url='/dashboard' />}} />
    },
    {
      url : '/logout',
      action : <SessionLogout />
    },
    {
      url : '/',
      action : <SessionCheck data={{error: <Login />, success : <Redirect url='/dashboard' />}} />
    },
  ];

  return (
    <AppRouter data={appRoutes} />
  )
}
