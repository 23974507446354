import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import MasterTemplates from './../configs/templates';

import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import Formats from './../utils/data_formats';
import Lng from './../utils/lng';
import CombinationData from './../components/combination_data';

import Popup from './../components/common/popup_new';

import {useParams} from 'react-router-dom';


class Combination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      master:null,
      hidden:{},
      templateCode:this.props.params.calendar,
      categoryId:this.props.params.categoryId,
      popupProps: {
        status: false,
        opts: {
          size: 12,
          head: '',
          content: '',
        },
        onHide: () => {
          let popupProps = this.state.popupProps;
          popupProps.status = false;
          this.setState({popupProps:popupProps});
        }
      },
      counter : {
        total: 0,
        monthly: 0
      },

      template: {
        default_base: 'Default / International English',
        country: '',
        category: '',
        name: '',
        base: '',
        type: '',
        typeSub1: '',
        typeSub2: '',
        file: ''
      },
      base: {
        data: MasterTemplates.DATA,
      },
      data: [],
      selected: [],

    };
    this.fetchTemplate();
  }

  fetchTemplate = () => {
    let url = Api.combinationGetMaster+'?templateCode='+this.state.templateCode+'&categoryId='+this.state.categoryId;
    if(this.state.master) {
      let master = this.state.master;
      url = Api.combinationGetMaster+
        '?templateCode='+this.state.templateCode+
        '&categoryId='+this.state.categoryId+
        '&filter='+this.state.master.template_info.filter.selected;
    }
    if(this.state.master) {
      let master = this.state.master;
      master.rows = [];
      this.setState({master:master});
    }
    HttpUtil
      .getJson(url)
      .then(result => {
        console.log(result);
        try {
          if(result.meta.status===0) {
            let master = result.data;
            if(this.state.master) {
              master.template_info.filter = {...this.state.master.template_info.filter};
            }
            this.setState({master:master});
            this.getDataRowCounter(this.state.categoryId);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        //alert('Could not load data');
      });
  };

  fetchDataRow = (rowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        console.log(result);
        try {
          if(result.meta.status===0) {
            master.rows[rowIndex] = result.data;
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        //alert('Could not load data');
      });
  };

  getColumn = (obj, colNum) => {
    return obj['col'+colNum];
  };

  addMasterRow = (rowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetBlankDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let dataRow = result.data;
            for(let colIndex in this.state.master.template_info.columns) {
              let col = this.state.master.template_info.columns[colIndex];
              if(col.copy=='true') {
                dataRow['col'+col.col] = this.state.master.rows[rowIndex]['col'+col.col];
              }
            }
            master.rows[rowIndex].items.unshift(dataRow);
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  addDataRow = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    HttpUtil
      .getJson(Api.combinationGetBlankDataRow+'?masterRowId='+master.rows[rowIndex].id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let dataRow = result.data;
            for(let colIndex in this.state.master.template_info.columns) {
              let col = this.state.master.template_info.columns[colIndex];
              if(col.copy=='true') {
                dataRow['col'+col.col] = this.state.master.rows[rowIndex]['col'+col.col];
              }
            }

            //let dataRow = JSON.parse(JSON.stringify(master.rows[rowIndex].items[dataRowIndex]));
            dataRow.id = 0;
            master.rows[rowIndex].items.splice(dataRowIndex+1, 0, dataRow);
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  };

  dataGo = (rowIndex, dataRowIndex) => {
    let request = {
      newRow: this.state.master.rows[rowIndex].items[dataRowIndex],
      series: []
    };
    if(!request.newRow.editStarted) {
      let sno = 0;
      for(let i in this.state.master.rows[rowIndex].items) {
        let d = this.state.master.rows[rowIndex].items[i];
        if(i!=dataRowIndex && d.id==0) continue;
        sno += 1;
        if(i==dataRowIndex) {
          request.newRow.sno = sno;
          continue;
        }
        request.series.push({
          id: d.id,
          sno: sno
        });
      }
    }
    else {
      delete request.newRow.editStarted;
    }
    HttpUtil
      .postJson(Api.combinationPostAddDataRow, request)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let master = this.state.master;
            master.rows[rowIndex].items[dataRowIndex] = result.data;
            this.setState({master:master});
            this.updatePopupContent(rowIndex, dataRowIndex);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  }

  dataEdit = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    master.rows[rowIndex].items[dataRowIndex].editStarted = true;
    this.setState({master:master});
    this.updatePopupContent(rowIndex, dataRowIndex);
  }

  dataDelete = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    let request = {
      deleteRow: master.rows[rowIndex].items[dataRowIndex],
      series: []
    };
    let sno = 0;
    for(let i in master.rows[rowIndex].items) {
      let d = master.rows[rowIndex].items[i];
      if(i==dataRowIndex || d.id==0) continue;
      sno += 1;
      request.series.push({
        id: d.id,
        sno: sno
      });
    }
    HttpUtil
      .postJson(Api.combinationPostDeleteDataRow, request)
      .then(result => {
        try {
          if(result.meta.status===0) {
            delete master.rows[rowIndex].items[dataRowIndex];
            this.setState({master:master});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  }

  updatePopupContent = (rowIndex, dataRowIndex) => {
    let popupProps = this.state.popupProps;
    popupProps.opts.content = this.getPopupContent(rowIndex, dataRowIndex);
    this.setState({popupProps:popupProps});
  }

  getPopupContent = (rowIndex, dataRowIndex) => {
    let master = this.state.master;
    let dataRow = master.rows[rowIndex].items[dataRowIndex];

    let btnGo = (<button className="btn btn-success btn-sm my-btn" disabled>Go</button>);
    let btnEdit = (<button className="btn btn-warning btn-sm my-btn" onClick={() => this.dataEdit(rowIndex, dataRowIndex)}>Edit</button>);
    let btnDel = (
      <button className="btn btn-danger btn-sm my-btn" onClick={() => this.dataDelete(rowIndex, dataRowIndex)}>
        <i className="fa fa-times" />
      </button>
    );

    if(dataRow.id==0) {
      btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
      btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
      btnDel = (
        <button className="btn btn-danger btn-sm my-btn" onClick={() => {
            let master = this.state.master;
            delete master.rows[rowIndex].items[dataRowIndex];
            this.setState({master:master});
          }}><i className="fa fa-times" /></button>
      );
    }

    if(dataRow.editStarted) {
      btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
      btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-striped_ myTable" id="dataTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              {
                this.state.master.template_info.columns
                  .filter(col => col.negative=='true')
                  .map((c, index) => {
                    return (<th key={index}>{c.label}</th>);
                })
              }
              <th style={{width:'150px'}}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.state.master.template_info.columns
               .filter(col => col.negative=='true')
               .map((col, colIndex) => {
                let val = this.getColumn(dataRow, col.col);
                let cType = col.type.split('.');
                if(col.edit=='false' || (!dataRow.editStarted && col.edit=='true' && dataRow.id!=0)) {
                  if (cType[0]=='select') {
                    let selectData = CombinationData.get(cType[1]);
                    for(let i in selectData) {
                      if(selectData[i][0]==val && val!='') {
                        return (<td key={colIndex} style={{color:'#f00'}}>{selectData[i][1]}</td>);
                      }
                    }
                    return (<td key={colIndex}>{val}</td>);
                  }
                  return (<td key={colIndex}>{val}</td>);
                }
                if (cType[0]=='num') {
                  return (
                    <td key={colIndex}>
                      <input
                        type="number"
                        className="form-control my-field"
                        value={(val==null)?'':val}
                        style={{color:'#f00'}}
                        onChange={(e) => {
                          let master = this.state.master;
                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col ] = e.target.value;
                          this.setState({master:master});
                        }}/>
                    </td>
                  );
                }
                else if (cType[0]=='select') {
                  let selectData = CombinationData.get(cType[1]);
                  return (
                    <td key={colIndex}>
                      <select
                        className="form-control my-field"
                        value={(val==null)?'':val}
                        style={{color:'#f00'}}
                        onChange={(e) => {
                          let master = this.state.master;
                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col] = e.target.value
                          this.setState({master:master});
                          this.updatePopupContent(rowIndex, dataRowIndex);
                        }}>
                        {
                          selectData.map((d, index) => {
                            return (<option key={index} value={d[0]}>{d[1]}</option>);
                          })
                        }
                      </select>
                    </td>
                  );
                }
                return (
                  <td key={colIndex}>
                    <input
                      type="text"
                      className="form-control my-field"
                      value={(val==null)?'':val}
                      style={{color:'#f00'}}
                      onChange={(e) => {
                        let master = this.state.master;
                        master.rows[rowIndex].items[dataRowIndex]['col'+col.col ] = e.target.value;
                        this.setState({master:master});
                      }}/>
                  </td>
                );
              })}
              <td>
                {btnGo}
                {btnEdit}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  getDataRowCounter = (categoryId) => {
    HttpUtil
      .getJson(Api.combinationGetDataRowCounter+'?categoryId='+categoryId)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let monthlyCounter = 0;
            let totalCounter = result.data.byTotal;

            if(this.state.master) {
              for(let i=0; i<this.state.master.rows.length; i++) {
                for(let j=0; j<this.state.master.rows[i].items.length; j++) {
                  let dt = this.state.master.rows[i].items[j];
                  if(dt.id!=0) {
                    monthlyCounter += 1;
                  }
                }
              }  
            }
            this.setState({counter: {
              total: totalCounter,
              monthly: monthlyCounter
            }});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
      });
  };

  getPopupContentImport = () => {
    let importCont = (
      <div className="form-group alert alert-warning my-cont">
        No data found. Please choose specific calendars.
      </div>
    );
    if(this.state.data.length>0) {
      importCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan={7}>Calendar</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
            <tr>
              <th>Sno</th>
              <th>Country</th>
              <th>Category</th>
              <th>Name</th>
              <th>Base</th>
              <th>Type</th>
              <th>Sub Type 1</th>
              <th>Sub Type 2</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((d, index) => {
              if (d.is_generated == 0) {
                return <tr key={index} />;
              }
              //console.log(d);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{d.country}</td>
                  <td>{d.category}</td>
                  <td>{d.name}</td>
                  <td>{d.base}</td>
                  <td>{d.type}</td>
                  <td>{d.typeSub1}</td>
                  <td>{d.typeSub2}</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tbody>
            <tr>
              <td colSpan={100} style={{background:'#fff', textAlign:'right'}}>
                <button type="button" className="btn btn-success my-btn" onClick={() => {
                  setTimeout(() => {
                    alert('Could not save data');
                  }, 1000);
                }}>
                  Submit for Import
                </button>
              </td>
            </tr>
          </tbody>
        </table>  
      );
    }
    return (
      <div className="table-responsive">
        <table className="table table-bordered myTable" width="100%" cellSpacing="0">
          <tbody>
            <tr>
              <th>Calendar Base</th>
              <th>Calendar Type</th>
              <th>Calendar Sub Type 1</th>
              <th>Calendar Sub Type 2</th>
            </tr>
            <tr>
              <td style={{ width: '25%' }}>
                {this.selectCalendarBase()}
              </td>
              <td style={{ width: '25%' }}>
                {this.selectCalendarType()}
              </td>
              <td style={{ width: '25%' }}>
                {this.selectCalendarTypeSub1()}
              </td>
              <td style={{ width: '25%' }}>
                {this.selectCalendarTypeSub2()}
              </td>
            </tr>
          </tbody>
        </table>
        {importCont}
      </div>
    );
  };

  loadCategories = (template) => {
    HttpUtil
      .getJson(Api.combinationGetCategories+'?templateId='+template.id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            console.log(result.data);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  }

  getTemplateMaster = () => {
    this.setState({data: []});
    HttpUtil
      .getJson(Api.combinationTemplateMasterGet+'?templateType=cd')
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = [];
            for(let i in result.data) {
              let d = result.data[i];
              if((d.base+'').search(this.state.template.base)<0) continue;
              if((d.type+'').search(this.state.template.type)<0 && this.state.template.type!='') continue;
              if((d.typeSub1+'').search(this.state.template.typeSub1)<0 && this.state.template.typeSub1!='') continue;
              if((d.typeSub2+'').search(this.state.template.typeSub2)<0 && this.state.template.typeSub2!='') continue;
              data.push(d);
            }
            this.setState({data: data});
            setTimeout(() => {
              let popupProps = this.state.popupProps;
              popupProps.status = true;
              popupProps.opts.content = this.getPopupContentImport();
              this.setState({ popupProps: popupProps });
            }, 0);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  render () {
    if(this.state.master==null) return (
      <div className="row">
        <div className="col-lg-12">
          Loading Master Data ...
        </div>
      </div>
    );

    let innerContent = (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <table style={{width:'100%'}}>
              <tbody>
                <tr>
                  <td style={{width:'33%',textAlign:'left'}}>
                    <div className="m-0 font-weight-bold text-primary">
                      {this.state.master.template_info.id} - calendar maker - {this.state.master.category.label}
                    </div>
                    <div style={{fontSize:'12px'}}>
                      <strong>Monthly : {this.state.counter.monthly}</strong>, &nbsp;&nbsp;
                      <strong>Total : {this.state.counter.total}</strong>
                    </div>
                  </td>
                  <td style={{width:'33%',textAlign:'center'}}>
                    <div className="form-group">
                      <select
                        className="form-control my-field"
                        style={{display:'inline-block',width:'100px'}}
                        value={this.state.master.template_info.filter.selected}
                        onChange={(e) => {
                          let master = this.state.master;
                          master.template_info.filter.selected = e.target.value;
                          this.setState({master:master, counter: {total:0, monthly:0}});
                          this.fetchTemplate();
                        }}>
                        {
                          this.state.master.template_info.filter.labels.map((label, index) => {
                            let filter = this.state.master.template_info.filter;
                            return (
                              <option key={index} value={((parseInt(index)+1)+'')}>
                                {label}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </td>
                  <td style={{width:'33%',textAlign:'right',fontSize:'9px'}}>
                      <button className="btn btn-info my-btn" onClick={() => {
                        let popupProps = this.state.popupProps;
                        popupProps.status = true;
                        popupProps.opts.head = (
                          <div style={{ float: 'left' }}>
                            <span>Import from Collective Data</span><br />
                          </div>
                        );
                        popupProps.opts.size = 8;
                        popupProps.opts.content = this.getPopupContentImport();
                        this.setState({ popupProps: popupProps });
                      }}>
                        Import - Collective Data
                      </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template_info.columns
                      .filter(col => col.hide && col.negative=='false' && col.group)
                      .map((col, index) => {
                        return (
                          <th key={index} colSpan={col.group.colspan}>{col.group.label}</th>
                        );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template_info.columns
                      .filter(col => col.hide && col.negative=='false')
                      .map((col, index) => {
                        return (
                          <th key={index}>{col.label}</th>
                        );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th style={{width:'30px'}}>&nbsp;</th>
                    {this.state.master.template_info.columns
                      .filter(col => col.hide && col.negative=='false')
                      .map((col, index) => {
                      return (
                        <th style={{textAlign:'left'}} key={index}>
                          <input type="checkbox" style={{width:'10px'}} defaultChecked={!this.state.hidden[col.col]} onChange={(e) => {
                              let hidden = this.state.hidden;
                              if(e.target.checked) delete hidden[col.col];
                              else hidden[col.col] = true;
                              this.setState({hidden:hidden});
                            }} />
                        </th>
                      );
                    })}
                    <th style={{width:'150px'}}>&nbsp;</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th style={{width:'30px'}}>Sno</th>
                    {
                      this.state.master.template_info.columns
                        .filter(col => !this.state.hidden[col.col] && col.negative=='false')
                        .map((c, index) => {
                          return (<th key={index}>{c.label}</th>);
                      })
                    }
                    <th style={{width:'150px'}}></th>
                  </tr>
                </thead>
                {
                  this.state.master.rows
                    .filter(row => this.state.master.template_info.filter.selected==this.getColumn(row, this.state.master.template_info.filter.mapped_column))
                    .map((row, rowIndex) => {
                    return (
                      <tbody key={rowIndex}>
                        <tr>
                          <td>{rowIndex+1}</td>
                          {this.state.master.template_info.columns
                            .filter(col => !this.state.hidden[col.col] && col.negative=='false')
                            .map((col, colIndex) => {
                            return (<td key={colIndex}>{this.getColumn(row, col.col)}</td>)
                          })}
                          <td>
                            <button className="btn btn-primary btn-sm my-btn" onClick={() => this.addMasterRow(rowIndex)}>
                              <i className="fa fa-plus" />
                            </button>
                            <button className="btn btn-success btn-sm my-btn" disabled>
                              Go
                            </button>
                            <button className="btn btn-warning btn-sm my-btn">
                              Edit
                            </button>
                          </td>
                        </tr>
                        {row.items.map((dataRow, dataRowIndex) => {

                          let btnGo = (<button className="btn btn-success btn-sm my-btn" disabled>Go</button>);
                          let btnEdit = (<button className="btn btn-warning btn-sm my-btn" onClick={() => this.dataEdit(rowIndex, dataRowIndex)}>Edit</button>);
                          let btnDel = (
                            <button className="btn btn-danger btn-sm my-btn" onClick={() => this.dataDelete(rowIndex, dataRowIndex)}>
                              <i className="fa fa-times" />
                            </button>
                          );

                          if(dataRow.id==0) {
                            btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
                            btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
                            btnDel = (
                              <button className="btn btn-danger btn-sm my-btn" onClick={() => {
                                  let master = this.state.master;
                                  delete master.rows[rowIndex].items[dataRowIndex];
                                  this.setState({master:master});
                                }}><i className="fa fa-times" /></button>
                            );
                          }

                          if(dataRow.editStarted) {
                            btnGo = (<button className="btn btn-success btn-sm my-btn" onClick={() => this.dataGo(rowIndex, dataRowIndex)}>Go</button>);
                            btnEdit = (<button className="btn btn-warning btn-sm my-btn" disabled>Edit</button>);
                          }

                          return (
                            <tr style={{background:'#fff'}} key={dataRowIndex}>
                              <td>{CombinationData.subSno(rowIndex+1, dataRowIndex+1)}</td>
                              {this.state.master.template_info.columns
                                .filter(col => (!this.state.hidden[col.col] && col.negative=='false'))
                                .map((col, colIndex) => {
                                let val = this.getColumn(dataRow, col.col);
                                let cType = col.type.split('.');
                                if(col.edit=='false' || (!dataRow.editStarted && col.edit=='true' && dataRow.id!=0)) {
                                  if (cType[0]=='select') {
                                    let selectData = CombinationData.get(cType[1]);
                                    for(let i in selectData) {
                                      if(selectData[i][0]==val) {
                                        return (<td key={colIndex}>{selectData[i][1]}</td>);
                                      }
                                    }
                                    return (<td key={colIndex}>{val}</td>);
                                  }
                                  return (<td key={colIndex}>{val}</td>);
                                }
                                if (cType[0]=='num') {
                                  return (
                                    <td key={colIndex}>
                                      <input
                                        type="number"
                                        className="form-control my-field"
                                        value={(val==null)?'':val}
                                        onChange={(e) => {
                                          let master = this.state.master;
                                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col ] = e.target.value;
                                          this.setState({master:master});
                                        }}/>
                                    </td>
                                  );
                                }
                                else if (cType[0]=='select') {
                                  let selectData = CombinationData.get(cType[1]);
                                  return (
                                    <td key={colIndex}>
                                      <select
                                        className="form-control my-field"
                                        value={(val==null)?'':val}
                                        onChange={(e) => {
                                          let master = this.state.master;
                                          master.rows[rowIndex].items[dataRowIndex]['col'+col.col] = e.target.value
                                          this.setState({master:master});
                                          //console.log(master.rows[rowIndex].items[dataRowIndex]);
                                        }}>
                                        {
                                          selectData.map((d, index) => {
                                            return (<option key={index} value={d[0]}>{d[1]}</option>);
                                          })
                                        }
                                      </select>
                                    </td>
                                  );
                                }
                                return (
                                  <td key={colIndex}>
                                    <input
                                      type="text"
                                      className="form-control my-field"
                                      value={(val==null)?'':val}
                                      onChange={(e) => {
                                        let val = e.target.value;
                                        let master = this.state.master;
                                        master.rows[rowIndex].items[dataRowIndex]['col'+col.col] = val;
                                        if(col.lng) {
                                          let lngResult = Lng.get(val);
                                          if(col.lng.col_lng) master.rows[rowIndex].items[dataRowIndex]['col'+col.lng.col_lng] = lngResult.lng;
                                          if(col.lng.col_unicode) master.rows[rowIndex].items[dataRowIndex]['col'+col.lng.col_unicode] = lngResult.unicode;
                                        }
                                        this.setState({master:master});
                                      }}/>
                                  </td>
                                );
                              })}
                              <td>
                                <button className="btn btn-primary btn-sm my-btn" onClick={() => this.addDataRow(rowIndex, dataRowIndex)}>
                                  <i className="fa fa-plus" />
                                </button>
                                {btnGo}
                                {btnEdit}
                                {btnDel}
                                <button className="btn btn-danger my-btn" onClick={() => {
                                  let popupProps = this.state.popupProps;
                                  popupProps.status = true;
                                  popupProps.opts.head = (
                                    <div style={{float:'left'}}>
                                      <span>Negative Values</span><br />
                                    </div>
                                  );
                                  popupProps.opts.content = this.getPopupContent(rowIndex, dataRowIndex);
                                  this.setState({popupProps:popupProps});
                                }}>
                                  <i className="fa fa-skull-crossbones" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )
                  })
                }
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup props={this.state.popupProps} />
    </div>
    );
    return innerContent
  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Festival - Add</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Combination
