import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import MasterTemplates from './../../configs/templates';

import Dashboard from './../../pages/dashboard';
import Popup from './../../components/common/popup_new';

class DashboardCollectiveDataOpen extends Component {

  state = {
    template: {
      default_base: 'Default / International English',
      country: '',
      category: '',
      name: '',
      base: '',
      type: '',
      typeSub1: '',
      typeSub2: '',
      file: ''
    },
    base: {
      data: MasterTemplates.DATA,
    },
    data: [],
    popup: {
      show: false,
      size: 6,
      head: '',
      content: '',
    },
    selected: null,
    categories: [],
    categoryValue: null,
    categoryMake: false,
  };

  componentDidMount = () => {
    
  };

  getTemplateMaster = () => {
    this.setState({data: []});
    HttpUtil
      .getJson(Api.combinationTemplateMasterGet+'?templateType=event')
      .then(result => {
        try {
          if(result.meta.status===0) {
            let data = [];
            for(let i in result.data) {
              let d = result.data[i];
              if((d.base+'').search(this.state.template.base)<0) continue;
              if((d.type+'').search(this.state.template.type)<0 && this.state.template.type!='') continue;
              if((d.typeSub1+'').search(this.state.template.typeSub1)<0 && this.state.template.typeSub1!='') continue;
              if((d.typeSub2+'').search(this.state.template.typeSub2)<0 && this.state.template.typeSub2!='') continue;
              data.push(d);
            }
            this.setState({data: data});
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not process');
      });
  };

  loadCategories = (d) => {
    HttpUtil
      .getJson(Api.combinationGetCategories+'?templateId='+d.id)
      .then(result => {
        try {
          if(result.meta.status===0) {
            let req = {...d};
            req.categoryId = 0;
            for(let i in result.data) {
              req.categoryId = result.data[i].id;
              break;
            }
            this.getTemplateFormat(req);
            return;
          }
        }
        catch(e) {
          console.log(result);
          console.log(e);
        }
        alert('Could not load data');
      });
  }

  getTemplateFormat = (d) => {
    let json = JSON.parse(d.info);

    let popup = this.state.popup;
    popup.show = true;
    popup.size = 10;
    popup.head = 'Preview - '+d.country+' ('+d.category+') - '+d.base+'';

    let rows = [];
    for(let i in json.generate.months) {
      let _m = json.generate.months[i];
      for(let j in json.generate.dates) {
        let _d = json.generate.dates[j];
        rows.push((
          <tr key={i+'_'+j}>
            <td>{parseInt(i)+1}</td>
            <td>{_m}</td>
            <td>{parseInt(j)+1}</td>
            <td>{_d}</td>
          </tr>
        ));
      }
    }

    popup.content = (
      <div>
        <div style={{overflow:'auto', maxHeight:'500px'}}>
          <table className="table table-bordered myTable" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th>Month No</th>
                <th>Month Name</th>
                <th>Date No</th>
                <th>Date Name</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((tr, index) => tr)}
            </tbody>
          </table>
        </div>
        <div className="form-group" style={{textAlign:'center'}}>
          <a className="btn btn-primary my-btn" target="blank" href={'/calendar/'+d.id+'/'+d.categoryId}>
            Next
          </a>
        </div>
      </div>
    );
    this.setState({popup:popup});
  }

  selectCalendarBase = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(calendarDataObj[d.base]) continue;
      calendarDataObj[d.base] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.base = e.target.value;
        template.type = '';
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarType = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if(this.state.template.base!=d.base || calendarDataObj[d.type]) continue;
      calendarDataObj[d.type] = 'true';
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.type = e.target.value;
        template.typeSub1 = '';
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub1 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type) && !calendarDataObj[d.typeSub1]) {
        calendarDataObj[d.typeSub1] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub1 = e.target.value;
        template.typeSub2 = '';
        template.file = '';
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  selectCalendarTypeSub2 = () => {
    let calendarData = [];
    let calendarDataObj = {};
    for(let i in this.state.base.data) {
      let d = this.state.base.data[i];
      if((this.state.template.base==d.base && this.state.template.type==d.type && this.state.template.typeSub1==d.typeSub1) && !calendarDataObj[d.typeSub2]) {
        calendarDataObj[d.typeSub2] = 'true';
      }
    }
    for(let d in calendarDataObj) {
      calendarData.push(d);
    }
    return (
      <select className="form-control my-field" onChange={(e) => {
        let template = this.state.template;
        template.typeSub2 = e.target.value;
        template.file = (template.base+'_'+template.type+'_'+template.typeSub1+'_'+template.typeSub2).toLocaleLowerCase();
        this.setState({template:template});
        this.getTemplateMaster();
      }}>
        <option value="">-- Select --</option>
        {calendarData.map((d, index) => {
          return (<option value={d} key={index}>{d}</option>);
        })}
      </select>
    );
  }

  render = () => {
    let openCont = (
      <div className="form-group alert alert-warning">
        No data found. Please choose specific calendars.
      </div>
    );
    if(this.state.data.length>0) {
      openCont = (
        <table className="table table-bordered table-striped myTable" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan={7}>Calendar</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
            <tr>
              <th>Sno</th>
              <th>Country</th>
              <th>Category</th>
              <th>Name</th>
              <th>Base</th>
              <th>Type</th>
              <th>Sub Type 1</th>
              <th>Sub Type 2</th>
              <th colSpan={2}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((d, index) => {
              if (d.is_generated == 0) {
                return <tr key={index} />;
              }
              console.log(d);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{d.country}</td>
                  <td>{d.category}</td>
                  <td>{d.name}</td>
                  <td>{d.base}</td>
                  <td>{d.type}</td>
                  <td>{d.typeSub1}</td>
                  <td>{d.typeSub2}</td>
                  <td style={{ width: '40px' }}>
                    <button type="button" className="btn btn-primary my-btn" onClick={() => {
                      this.loadCategories(d);
                    }}>
                      Open
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>  
      );
    }

    let innerContent = (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Open Collective Data</h6>
            </div>
            <div className="card-body my-cont">
              <table className="table table-bordered myTable" width="100%" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Calendar Base</th>
                    <th>Calendar Type</th>
                    <th>Calendar Sub Type 1</th>
                    <th>Calendar Sub Type 2</th>
                  </tr>
                  <tr>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarBase()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarType()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub1()}
                    </td>
                    <td style={{width:'25%'}}>
                      {this.selectCalendarTypeSub2()}
                    </td>
                  </tr>
                </tbody>
              </table>
              {openCont}
            </div>
          </div>
        </div>

        <Popup props={{
          status: this.state.popup.show,
          opts: {
            size: this.state.popup.size,
            head: this.state.popup.head,
            content: this.state.popup.content
          },
          onHide: () => {
            let popup = this.state.popup;
            popup.show = false;
            popup.content = <span />;
            this.setState({popup:popup});
          }
        }} />
      </div>
    );

    let parentProps = {
      title: '',
      header: <Header />,
      content: innerContent,
      footer: <Footer />,
    };

    return (
      <Dashboard {...parentProps} />
    )
  };
}

const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Home - Nirwanji</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default DashboardCollectiveDataOpen;
