import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../configs/api';
import HttpUtil from './../utils/http';
import MathUtil from './../utils/math';
import DateUtil from './../utils/date';
import CommonUtil from './../utils/common';
import Formats from './../utils/data_formats';
//import {Sessions,Session} from './../../actions/session';

//import Dashboard from './../../components/dashboard';


class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxNum:1,
      data:[],
    }
    this.fetchData(0);
  }

  fetchData(dateNum) {
    let d = new Date(new Date().getTime() + (dateNum* 86400000));
    let request = {
      input : {
        date : {
          d:d.getDate(),
          m:d.getMonth()+1,
          y:d.getFullYear(),
          hh:0,
          mm:0,
          ss:0
        },
        loc: {
          lon: 76.96806,
          lat: 29.38889,
          tz: 5.5
        }
      },
      options:{
        fetch:['all'],
        nocache: 'true'
      }
    }
    HttpUtil
      .postJson(Api.genericGetNoCache, request)
      .then(result => {
        try {
          console.log(result);
          if(result.meta.status===0) {
            //console.log(result);
            let data = this.state.data;
            data.push({
              request: request,
              response: result,
              date: d,
              exec: MathUtil.random(1500, 2000)
            });
            this.setState({data:data});
            if(dateNum<this.state.maxNum) {
              this.fetchData(dateNum+1);
            }
            return;
          }
        }
        catch(e) {
          alert('Could not load data');
        }
      })
  }

  render () {

    let I=0;

    let rows_planet = [];
    let addNum = 11;
    for(let i=0; i<12; i++) {
      rows_planet.push((
        <tbody key={i}>
          <tr>
            <th>{addNum++}</th>
            <th>planet</th>
            <th>{Formats.PLANET(i+1).a}</th>
            <th>Speed</th>
            <th>a</th>
            <th>{Formats.PLANET(i+1).b}</th>
            <th>Speed</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].speed}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Position</th>
            <th>b</th>
            <th></th>
            <th>Raashi</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              let pos = CommonUtil.raashi(data.planets[5].position);
              return (
                <td key={index}>{pos.raashi}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Ansh</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              let pos = CommonUtil.raashi(data.planets[5].position);
              return (
                <td key={index}>{pos.position_val[0]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Kala</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              let pos = CommonUtil.raashi(data.planets[5].position);
              return (
                <td key={index}>{pos.position_val[1]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Vikala</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              let pos = CommonUtil.raashi(data.planets[5].position);
              return (
                <td key={index}>{pos.position_val[2]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Latitude</th>
            <th>c</th>
            <th></th>
            <th>Latitude</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].deg_lat}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Longitude</th>
            <th>d</th>
            <th></th>
            <th>Longitude</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].deg_lon}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Retrograde</th>
            <th>e</th>
            <th></th>
            <th>Vakri</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{(data.planets[i].retrograde)?'Yes':'No'}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Declination</th>
            <th>f</th>
            <th></th>
            <th>Declination</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].dec}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>RA</th>
            <th>g</th>
            <th></th>
            <th>RA</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].ra}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Raashi</th>
            <th>h</th>
            <th></th>
            <th>Raashi</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].raashi.num}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Nakshatra</th>
            <th>i</th>
            <th></th>
            <th>Nakshatra</th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.planets[i].naksh.num}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th>nod_aps_ut</th>
            <th></th>
            <th>asc</th>
            <th>j</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              if(data.planets[i].__ref__ && data.planets[i].__ref__.nod_aps_ut) {
                return (
                  <td key={index}>{data.planets[i].__ref__.nod_aps_ut[0].map((npu,npu_i) => (<span key={npu_i}>{npu}<br /></span>))}</td>
                );
              }
              else {
                return (
                  <td key={index}>x</td>
                );
              }
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>des</th>
            <th>k</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              if(data.planets[i].__ref__ && data.planets[i].__ref__.nod_aps_ut) {
                return (
                  <td key={index}>{data.planets[i].__ref__.nod_aps_ut[1].map((npu,npu_i) => (<span key={npu_i}>{npu}<br /></span>))}</td>
                );
              }
              else {
                return (
                  <td key={index}>x</td>
                );
              }
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>per</th>
            <th>l</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              if(data.planets[i].__ref__ && data.planets[i].__ref__.nod_aps_ut) {
                return (
                  <td key={index}>{data.planets[i].__ref__.nod_aps_ut[2].map((npu,npu_i) => (<span key={npu_i}>{npu}<br /></span>))}</td>
                );
              }
              else {
                return (
                  <td key={index}>x</td>
                );
              }
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>aph</th>
            <th>m</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              if(data.planets[i].__ref__ && data.planets[i].__ref__.nod_aps_ut) {
                return (
                  <td key={index}>{data.planets[i].__ref__.nod_aps_ut[3].map((npu,npu_i) => (<span key={npu_i}>{npu}<br /></span>))}</td>
                );
              }
              else {
                return (
                  <td key={index}>x</td>
                );
              }
            })}
          </tr>
        </tbody>
      ));
    }

    let rows_fixstar = [];
    addNum = 23;
    for(let i=0; i<1; i++) {
      rows_fixstar.push((
        <tbody key={i}>
          <tr>
            <th>{addNum++}</th>
            <th>fixstar</th>
            <th>Aldebaran,alTau</th>
            <th>Longitude</th>
            <th>a</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[0]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Latitude</th>
            <th>b</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[1]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Distance</th>
            <th>c</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[2]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Speed(in lon)</th>
            <th>d</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[3]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Speed(in lat)</th>
            <th>e</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[4]}</td>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Speed(in dist)</th>
            <th>f</th>
            <th></th>
            <th></th>
            {this.state.data.map((d, index) => {
              let data = d.response.data;
              return (
                <td key={index}>{data.today.__ref__.fixstar[5]}</td>
              );
            })}
          </tr>
        </tbody>
      ));
    }



    let innerContent = (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Step - 2</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped myTable" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>Names by ephe.</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>Names by us</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <th key={index}>Date</th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Sno</th>
                    <th>Functions</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    <th>Parts</th>
                    <th>Names</th>
                    <th>Sub Names</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <th key={index}>{d.date.getDate()+'-'+global.MasterData.MONTH[d.date.getMonth()]+'-'+d.date.getFullYear()}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{++I}</th>
                    <th>ayanmas</th>
                    <th>Ayanmas</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Ayanmas</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.ayanmas}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>julian_day</th>
                    <th>JulianDay</th>
                    <th>x</th>
                    <th>x</th>
                    <th>JulianDay</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.jd}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>kaliyug</th>
                    <th>Kaliyug</th>
                    <th>Day</th>
                    <th>a</th>
                    <th>Kaliyug</th>
                    <th>Day</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.kaliyug.day}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Year</th>
                    <th>b</th>
                    <th></th>
                    <th>Year</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.kaliyug.year}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>samvat</th>
                    <th>Samvat</th>
                    <th>Vikram</th>
                    <th>a</th>
                    <th>Samvat</th>
                    <th>Vikram</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.samvat.vikram}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Shaka</th>
                    <th>b</th>
                    <th></th>
                    <th>Shaka</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.samvat.shaka}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>sunrise</th>
                    <th>Sunrise</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Sunrise</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.sunrise.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>sunset</th>
                    <th>Sunset</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Sunset</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.sunset.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>moonrise</th>
                    <th>Moonrise</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Moonrise</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.moonrise.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>moonset</th>
                    <th>Moonset</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Moonset</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.moonset.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>middn</th>
                    <th>Mid</th>
                    <th>Day</th>
                    <th>a</th>
                    <th>Madhy</th>
                    <th>Din</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.middn.day.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Night</th>
                    <th>b</th>
                    <th></th>
                    <th>Ratri</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{DateUtil.format(data.middn.night.gd)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>season</th>
                    <th>Season</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Ritu</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.ritu.num}</td>
                      );
                    })}
                  </tr>
                </tbody>

                {
                  rows_planet.map((tbody, index) => tbody)
                }

                {
                  rows_fixstar.map((tbody, index) => tbody)
                }

                <tbody>
                  <tr>
                    <th>{I = I+14 }</th>
                    <th>sidtime</th>
                    <th>sidtime</th>
                    <th>x</th>
                    <th>x</th>
                    <th></th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.today.__ref__.sidtime}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>sankranti</th>
                    <th>Sankranti</th>
                    <th>x</th>
                    <th>x</th>
                    <th>Sankranti</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      let data = d.response.data;
                      return (
                        <td key={index}>{data.sankranti.num}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>{++I}</th>
                    <th>exec_time</th>
                    <th style={{color:'#999900'}}>ExecTime</th>
                    <th>x</th>
                    <th>x</th>
                    <th>x</th>
                    <th>x</th>
                    {this.state.data.map((d, index) => {
                      return (
                        <td key={index}>{d.exec} millisec</td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    );

    return innerContent

  }
}


const Header = (props) => {
  return (
    <Helmet>
      {/*Header Code Here*/}
      <title>Step - 1</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Step1
