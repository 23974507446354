import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import Api from './../../configs/api';
import HttpUtil from './../../utils/http';
import {Sessions} from './../../actions/session';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      input : {
        key: '',
        keyAuth: '',
      },
      status : '',
    }
    this.setKey = this.setKey.bind(this);
    this.setKeyAuth = this.setKeyAuth.bind(this);
  }

  setKey(event) {
    let input = this.state.input;
    input.key = event.target.value;
    this.setState({ input : input})
  }
  setKeyAuth(event) {
    let input = this.state.input;
    input.keyAuth = event.target.value;
    this.setState({ input : input})
  }

  processLogin(event) {
    if(
      this.state.input.key.trim()==='' ||
      this.state.input.keyAuth.trim()===''
    ) {
      this.showStatus(1, 'No field should be blank');
      return;
    }
    HttpUtil
      .postJson(Api.login, {
        username: this.state.input.key,
        password: this.state.input.keyAuth
      })
      .then(result => {
        console.log(result);
        if(result.meta.status===0) {
          Sessions.set(result.data);
          this.showStatus(result.meta.status, 'Login Successfull. Redirecting you to dashboard ....', false);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        else {
          this.showStatus(result.meta.status, result.meta.message)
        }
      })
  }

  showStatus(type, message, autoHide) {
    if(typeof(autoHide)==='undefined') autoHide = true;
    let statusClass = type===0?'success':'danger';
    let status = <div className={"alert alert-"+(statusClass)}>{message}</div>;
    this.setState({status:status});
    if(!autoHide) return;
    setTimeout(() => {
      this.setState({status:''})
    }, 2000);
  }

  render () {
    return (
      <div>
        <Header />
        <Content object={this} />
        <Footer />
      </div>
    )
  }
}

const Content = (props) => {
  let object = props.object;

  return (
    <div className="row justify-content-center">
      <div className="col-xl-3 col-lg-3 col-md-6">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Login - Nirwanji</h1>
                  </div>
                  <form className="user" onSubmit={() => false}>
                    <div className="form-group">
                      <input type="text" className="form-control form-control-user" onChange={object.setKey} placeholder="Enter Login Key" />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control form-control-user" onChange={object.setKeyAuth} placeholder="Enter Password" />
                    </div>
                    {/*
                    <div className="form-group">
                      <div className="custom-control custom-checkbox small">
                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                        <label className="custom-control-label" for="customCheck">Remember Me</label>
                      </div>
                    </div>
                    */}
                    <div>{object.state.status}</div>
                    <button type="button" onClick={object.processLogin.bind(object)} className="btn btn-primary btn-user btn-block">
                      Login
                    </button>
                  </form>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = (props) => {
  return (
    <Helmet bodyAttributes={{class: 'bg-gradient-primary'}}>
      {/*Header Code Here*/}
      <title>Login - Dashboard</title>
    </Helmet>
  )
}

const Footer = (props) => {
  return (
    <div>
      {/* Footer Code Here */}
    </div>
  )
}

export default Login
