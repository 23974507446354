class DateUtil {

  static format(gd) {
    let m = (gd[1]<10)?'0'+gd[1]:gd[1];
    let d = (gd[2]<10)?'0'+gd[2]:gd[2];
    let hh = (gd[3]<10)?'0'+gd[3]:gd[3];
    let mm = (gd[4]<10)?'0'+gd[4]:gd[4];
    let ss = (gd[5]<10)?'0'+gd[5]:gd[5];
    return gd[0]+'-'+m+'-'+d+' '+hh+':'+mm+':'+ss;
  }

  static hm(gd) {
    let hh = (gd[3]<10)?'0'+gd[3]:gd[3];
    let mm = (gd[4]<10)?'0'+gd[4]:gd[4];
    return hh+':'+mm;
  }

}


export default DateUtil
